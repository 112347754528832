import { ViolinChart } from "./ViolinChart";
import { InfoCircle } from "react-bootstrap-icons";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import ReactMarkdown from "react-markdown";
import ReactDOM from "react-dom/client";

const ViolinLayout = ({
  dataset,
  marker = false,
  markerData = { label: "", value: 0.0 },
  title,
  info_button = false,
  scale_info,
  showDataPoints = false,
  xtitle = "Score",
  ytitle = "",
  multiple = false,
  scatters = [0],
}) => {
  //calculate margins
  let targetLength = 0;
  let targetLabel = ""
  let margins = [100, 10, 10, 100]; // [l, r, t, b]

  if (markerData.value === 0) {
    marker = false;
  }

  //
  // auto adjust left margin if text gets larger than 100 px
  if (multiple) {
    dataset.forEach((item) => {
      if (item.target.length > targetLength) {
        targetLength = item.target.length;
        targetLabel = item.target
      }
    });
    if (targetLength * 7 > margins[0]) {
      let numCapitals = (targetLabel.match(/[A-Z]/g) || []).length;
      margins[0] = (targetLength - numCapitals) * 7 + numCapitals * 9;
    }
  }

  const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary ",
      },
      buttonsStyling: false,
    })
  );

  const fireModal = (input, error = false) => {
    const container = document.createElement("div");

    // Render the ReactMarkdown component into the container
    const root = ReactDOM.createRoot(container);
    root.render(<ReactMarkdown>{input["description"]}</ReactMarkdown>);

    MySwal.fire({
      title: <h4>{input["title"]}</h4>,
      html: container,
      showCancelButton: false,
      confirmButtonText: "close",
    });
  };

  const handleInfoButton = (e) => {
    e.preventDefault();
    fireModal({ title: title, description: scale_info });
  };

  return (
    <>
      {scatters.map((e, i) => (
        <div key={"scatter_" + i} className="col-lg-6  mt-3">
          <div className="box">
            <div className="d-flex box-title justify-content-between">
              <div className="box-title">{title}</div>
              {info_button && (
                <div
                  role="button"
                  onClick={(e) => {
                    handleInfoButton(e);
                  }}
                  className="info-button"
                >
                  <InfoCircle color="#0B3954" size={24} />
                </div>
              )}
            </div>

            <ViolinChart
              marker={marker}
              markerData={markerData}
              xtitle={xtitle}
              ytitle={ytitle}
              dataset={dataset}
              showDataPoints={showDataPoints}
              margins={margins}
              multiple={multiple}
              unique={"scatter_" + i}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default ViolinLayout;
