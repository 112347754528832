import {
  Outlet,
  ScrollRestoration,
  useLocation,
  useNavigate,
} from "react-router-dom";

import { useSelector } from "react-redux";
import React, { useState } from "react";
import { selectActiveRole } from "../features/auth/authSlice";

import {
  Menu,
  Sidebar,
  SubMenu,
  MenuItem,
} from "react-pro-sidebar";
import MenuHeader from "./components/MenuHeader";
import { MenuFooter } from "./components/MenuFooter";
import { menuItemStyles } from "../styles/MenuColors";
import { menuColors } from "../styles/MenuColors";

const Layout = ({ menuArray, toggled, setToggled }) => {
  const location = useLocation();
  const activeRole = useSelector(selectActiveRole);
  const navigate = useNavigate();
  const [collapsed, setCollapsed] = useState(false);
  const [broken, setBroken] = useState(false);

  const handleOnClick = (path) => {
    navigate(path);
  };

  const getMenuItems = (item) => {
    if (!item.subItems)
      return (
        <MenuItem
          onClick={() => {
            handleOnClick(item.path);
          }}
          active={location.pathname.includes(item.path)}
        >
          {" "}
          {item.label}{" "}
        </MenuItem>
      );

    return (
      <>
        <SubMenu
          active={location.pathname.includes(item.path)}
          label={item.label}
        >
          {item.subItems.map((subItem) => {
            if (subItem.roles.includes(activeRole)) {
              return (
                <MenuItem
                  className=""
                  onClick={() => {
                    handleOnClick(item.path + subItem.path);
                  }}
                  active={location.pathname.includes(item.path + subItem.path)}
                >
                  {" "}
                  {subItem.label}
                </MenuItem>
              );
            }
          })}
        </SubMenu>
      </>
    );
  };

  return (
    <>
      <Sidebar
        collapsed={collapsed}
        toggled={toggled}
        onBackdropClick={() => setToggled(false)}
        onBreakPoint={setBroken}
        rtl={false}
        breakPoint="md"
        backgroundColor={menuColors.sidebar.backgroundColor}
        rootStyles={{
          color: menuColors.sidebar.color,
        }}
      >
        <div className="side-bar">
          <MenuHeader />
          <Menu menuItemStyles={menuItemStyles}>
            {menuArray.map((item) => {
              if (item.roles.includes(activeRole)) {
                return getMenuItems(item);
              }
            })}
          </Menu>
          <MenuFooter />
        </div>
      </Sidebar>

      <Outlet />
      <ScrollRestoration />
    </>
  );
};

export default React.memo(Layout);
