function TitleBar({ title }) {
  return (
    <div className="container-fluid page-title-bar ">
      <div className="row">
        <div className="col-12">
          <div className="container-fluid">
            <div className="page-title-bar-text">{title}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default TitleBar;
