import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject,
} from "../../features/projects/projectSlice";
import { useGetNetworkRelationsQuery } from "../../features/report/networkRelationsApiSlice";
import NetworkGraph from "../graph/NetworkGraph";
import Loader from "../Loader";
import FilterBar from "../../layout/FilterBar";

const NetworkRelations = () => {
  // here we have to filter the nodes and edges before sending to the Network Object
  const selectedProject = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);

  const {
    data: networkData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetNetworkRelationsQuery(selectedProject);

  function filterData(filter) {
    let dataset = [[], []];

    if (networkData.Nodes.length === 0 || networkData.Edges.length === 0)
      return null;

    if (filter === "costrutto") {
      dataset[0] = JSON.parse(JSON.stringify([...networkData.Nodes]));
      dataset[0].forEach((row) => {
        row["group"] = row["gruppo"];
        delete row["color"];
      });
      dataset[1] = JSON.parse(JSON.stringify([...networkData.Edges]));
    } else {
      dataset[0] = JSON.parse(JSON.stringify([...networkData.Nodes]));
      dataset[0].forEach((row) => {
        row["group"] = row[filter];
      });
      dataset[1] = JSON.parse(JSON.stringify([...networkData.Edges]));
    }

    return dataset;
  }

  let input = null;
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    input = filterData(filterArray[selectedFilters[0]]);
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
    <FilterBar selectedFilters={selectedFilters} />
      {
        input && <div className="container mt-3">
          <div className="box">
            <NetworkGraph Nodes={input[0]} Edges={input[1]} />
          </div>
        </div>
      }
    </>
  );
};

export default NetworkRelations;
