import { useEffect, useState } from "react";
import FormLabel from "../../components/FormLabel";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import {
  useUpdateAccountMutation,
  useGetAccountQuery,
} from "../../features/account/accountSlice";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { useTimezoneSelect, allTimezones } from "react-timezone-select";

export default function AccountSetting() {
  const [UpdateAccount] = useUpdateAccountMutation();

  const labelStyle = "original";

  const { options, parseTimezone } = useTimezoneSelect({
    labelStyle,
    allTimezones,
  });

  const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary m-2",
        cancelButton: "btn btn-secondary m-2",
      },
      buttonsStyling: false,
    })
  );

  const responseModal = (input, error = false) => {
    if (!error) {
      MySwal.fire({
        //title: <h3>{input.message.title}</h3>,
        html: <i>{input.message}</i>,
        showCancelButton: false,
        //cancelButtonText: "Home",
        confirmButtonText: "Close",
        icon: "success",
      });
    } else
      MySwal.fire({
        title: <h3>{input.data.message}</h3>,
        html: <i>{input.data.data}</i>,
        confirmButtonText: "close",
        icon: "error",
      });
  };

  const { data, isLoading } = useGetAccountQuery("AccountData", {});

  const [accountData, setAccountData] = useState(null);

  useEffect(() => {
    if (data) {
      setAccountData({
        timezone: data.timezone,
      });
    }
  }, [data]);

  const handleChange = (data) => {
    setAccountData({ ...accountData, timezone: data.value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    async function asyncCall() {
      await UpdateAccount({
        timezone: accountData.timezone,
      })
        .unwrap()
        .then((payload) => {
          responseModal(payload);
        })
        .catch((error) => {
          responseModal(error, (error = true));
        });
    }
    asyncCall();
  };

  return (
    <>
      <div className="container container-settings ">
        <div className="row justify-content-md-center">
          <div className="col-lg-6">
            <div className="row">
              <div className="text-center mb-4">
                These settings will apply across your entire account
              </div>
            </div>
            <div className="row">
            {accountData && (
              <form id="settingsForm" onSubmit={handleSubmit}>
                <div className="mt-0">
                  <FormLabel label={"Timezone"} />
                  <select
                    value={accountData.timezone || ""}
                    onChange={(e) =>
                      handleChange(parseTimezone(e.currentTarget.value))
                    }
                  >
                    {options.map((option) => (
                      <option value={option.value}>{option.label}</option>
                    ))}
                  </select>

                  <div className="text-end mt-3">
                    <ButtonWithIcon
                      disabled={isLoading}
                      type="submit"
                      text={"save timezone"}
                    />
                  </div>
                </div>
              </form>
            )}

            </div>
            
          </div>
        </div>
      </div>
    </>
  );
}
