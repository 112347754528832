import { useEffect, useState } from "react";
import Plotly from "./customPlotly";
import createPlotlyComponent from "react-plotly.js/factory";

export const BarChartStacked = ({
  dataset,
  margins,
  xtitle = "Title x-axis",
  ytitle = "Title y-axis",
  barmode = "stack",
}) => {
  const [data, setData] = useState([]);
  const Plot = createPlotlyComponent(Plotly);

  useEffect(() => {
    let input = [];
    dataset.forEach((item) => {
      let x = [];
      let y = [];
      let legenda = [];
      let labels = [];
      item.forEach((e) => {
        y.push(e.y);
        x.push(e.x);
        labels.push(e.label.replace("\n", " - "));
        legenda.push(e.name);
      });
      let trace = {
        x: x,
        y: y,
        name: legenda[0],
        customdata: labels,
        hovertemplate: "%{customdata}",
        orientation: "h",
        marker: {
          //color: colorArray[0],
          width: 1,
        },
        type: "bar",
      };

      input.push(trace);
    });

    setData(input);
  }, [dataset]);

  return (
    <Plot
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={false}
      data={data}
      config={{ editable: false }}
      layout={{
        showlegend: true,
        barmode: barmode,
        margin: {
          l: margins[0],
          r: margins[1],
          t: margins[2],
          b: margins[3],
        },

        legend: {
          orientation: "h",
          yanchor: "bottom",
          y: 1.02,
          xanchor: "right",
          x: 1,
        },
        xaxis: {
          title: xtitle,
          ticksuffix: "  ",
          font: { size: "14" },
          zeroline: false,
        },
        yaxis: {
          categoryorder: "total ascending",
          title: ytitle,
          ticksuffix: "  ",
          font: { size: "14" },
          zeroline: false,
        },
        font: {
          size: "14",
        },
      }}
    />
  );
};
