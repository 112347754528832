import { ButtonWithIcon } from "../../../components/ButtonWithIcon";
import ModalComponent from "../../../features/modal/Modal";
import ChangeEmailForm from "./ChangeEmailForm";
import { useState } from "react";

export default function ChangeEmail({ currentEmail }) {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ModalComponent
        title={"Change email"}
        bodyText={<ChangeEmailForm currentEmail={currentEmail} />}
        size="lg"
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <div className="col-md-auto mt-3 mt-md-0">
        <ButtonWithIcon
          disabled={false}
          onClick={(e) => {
            setIsOpen(true);
          }}
          text={"Change email"}
        />
      </div>
    </>
  );
}
