export const locales = [
    {
        id: '',
        label: ''
    },
    {
        id: 'it',
        label: 'Italiano'
    }, {
        id: 'en',
        label: 'English'
    }
]
