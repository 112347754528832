import { GooglePieChart } from "./PieChart";

const PieChartLayout = ({ chart_id, pieData, title, color, perc = false }) => {
  let percentage;
  let cardTitel;
  if (perc) {
    percentage = Math.round((pieData["avgCompleted"] / pieData["total"]) * 100);
  }
  if (title) {
    cardTitel = `n. partecipants medio ${pieData["total"]}`;
  }

  return (
    <div className={"pie-layout py-3 w-100 "}>
      <GooglePieChart color={color} pieData={pieData} />
      <div
        style={{ fontWeight: 600 }}
        className="color-dark-gray text-center mt-3"
      >
        {cardTitel}
      </div>
      <div
        className="text-center perc-title"
        style={{ color: color.dark_code }}
      >
        {percentage} %
      </div>
    </div>
  );
};

export default PieChartLayout;
