import { apiSlice } from "../../api/apiSlice"

export const networkRelationsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getNetworkRelations: builder.query({
            query: (id) => {
                if (id) return `report/LHIPD/network-relations/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetNetworkRelationsQuery
} = networkRelationsApiSlice 