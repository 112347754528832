import { apiSlice } from "../../api/apiSlice"

export const trustBehavioursApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTrustBehaviours: builder.query({
            query: (id) => {
                if (id) return `report/TI/trust_behaviours/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetTrustBehavioursQuery
} = trustBehavioursApiSlice 