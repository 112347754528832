import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject
} from "../../features/projects/projectSlice";
import { useGetCompilationQuery } from "../../features/report/compilationApiSlice";
import Loader from "../Loader";
import ViolinLayout from "../graph/ViolinLayout";

const Compilation = () => {
  const selectedProject = useSelector(SelectCurrentProject)
  const { data, isLoading, isSuccess, isError, error } =
    useGetCompilationQuery(selectedProject);

  var dataset = [[]];
  function filterData(filter) {
    var data0 = [[]];

    data.tempi_di_compilazione.forEach((row) => {
      data0[0].push({ target: row[filter], score: row["durata_minuti"] });
    });

    return data0;
  }

  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    dataset = filterData("parte");
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <ViolinLayout
            multiple={true}
            title={"Compilation Time"}
            dataset={dataset[0]}
          />
        </div>
      </div>
    </>
  );
};

export default Compilation;
