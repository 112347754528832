import { Link } from "react-router-dom";

function MenuHeader() {
  return (
    <div className="menu-header">
      <Link to="/">
        <img src="/trustieMenuLogo.svg" height={36} alt="" />
      </Link>
    </div>
  );
}

export default MenuHeader;
