import impact_mock_data from "../../mockdata/impact_mock_data";
import ViolinCompareLayout from "../graph/ViolinCompareLayout";

const ImpactAnalysis = () => {
  

  // Function to generate a random number from a normal distribution
  function randomNormal(mean, sd) {
    let u1 = Math.random();
    let u2 = Math.random();
    let randStdNormal =
      Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
    return mean + sd * randStdNormal;
  }
  const data_before = impact_mock_data[0].scale_data[0];
  const data_after = impact_mock_data[0].scale_data[2].map((p) => ({
    ...p,
    value: p.value + randomNormal(1, 0.7),
  }));

  const data_before_control = impact_mock_data[0].scale_data[0];
  const data_after_control = impact_mock_data[0].scale_data[2];

  return (
    <>
      {
      data_before_control && data_after_control &&
      data_before && data_after && (
        <div className="container">
          <div className="row">
            <ViolinCompareLayout
              data_before={data_before}
              data_after={data_after}
              title={"Impact Analysis: Intervention Group"}
            />
            <ViolinCompareLayout
              data_before={data_before_control}
              data_after={data_after_control}
              title={"Impact Analysis: Control Group"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default ImpactAnalysis;
