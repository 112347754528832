import Longitudinal from "./Longitudinal";

const LongitudinalLayout = ({
  title = "this Is a title",
  data,
  time_points,
  scatters = [0],
}) => {
  let margins = [100, 100, 10, 100];

  return (
    <>
      {scatters.map((e, i) => (
        <div key={"scatter_" + i} className="col-lg-6  mt-3">
          <div className="box">
            <div className="d-flex box-title justify-content-between">
              <div className="box-title">{title}</div>
            </div>

            <Longitudinal
              data={data}
              time_points={time_points}
              margins={margins}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default LongitudinalLayout;
