import React, { useState, useEffect } from "react";
import { passwordRequirements } from "../../../components/functions/validation";
import { validatePassword } from "../../../components/functions/validation";
import { useNavigate } from "react-router-dom";
import { X, Check } from "react-bootstrap-icons";
import { useChangePasswordMutation } from "../../../features/auth/authApiSlice";
import { PasswordInput } from "../../../components/PasswordInput";

function ChangePasswordForm() {
  const [title, setTitle] = useState(null);
  const [currentPassword, setCurrentPassword] = useState("");
  const [newPassword, setNewPassword] = useState("");
  const [repeatPassword, setRepeatPassword] = useState("");
  const [focus, setFocus] = useState(false);
  const [showPasswordAlert, setShowPasswordAlert] = useState(false);
  const [requirements, setRequirements] = useState(passwordRequirements);

  const [isPasswordValid, setIsPasswordValid] = useState(false);
  const [isPasswordMatch, setIsPasswordMatch] = useState(false);
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState("Save Password");

  const navigate = useNavigate();
  const [changePassword] = useChangePasswordMutation();

  useEffect(() => {
    setShowPasswordAlert(newPassword.length > 0 && !focus && !isPasswordValid);
  }, [newPassword, focus, isPasswordValid]);

  const handleCurrentPassword = (e) => {
    e.preventDefault();
    setCurrentPassword(e.target.value);
  };

  const handleRepeatePassword = (e) => {
    e.preventDefault();
    setIsPasswordMatch(e.target.value === newPassword);
    setRepeatPassword(e.target.value);
  };

  const handleNewPassword = (e) => {
    e.preventDefault();
    const [isValid, updatedRequirements] = validatePassword(
      e.target.value,
      requirements
    );
    setIsPasswordValid(isValid);
    setRequirements(updatedRequirements);
    setNewPassword(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (success) {
      navigate("/login");
    } else {
      try {
        await changePassword([currentPassword, newPassword]).unwrap();
        setTitle("Password successfully reset!");
        setSuccess(true);
        setSubmitButtonText("Go to login...");
        setNewPassword("");
      } catch (err) {
        if (!err?.status) {
          setErrMsg("No Server Response");
        } else if (err.status === 400) {
          setErrMsg(err.data.message);
        } else if (err.status === 401) {
          setErrMsg("Request Failed");
        } else {
          setErrMsg("Request Failed");
        }
      }
    }
  };

  if (success) {
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <div className="row no-gutters">
          <div className="col-xl-12 bg-white">
            <div id="sign-in" className="auth-form form-validation">
              <form onSubmit={handleSubmit} className="form-validate">
                <h3 className="text-center mb-4 text-black">{title}</h3>
                <div className="text-center form-group mb-3">
                  <button type="submit" className="btn btn-primary btn-block">
                    {submitButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-center h-100 align-items-center">
      <div className="login-content style-2 ">
        <div className="row no-gutters">
          <div className="col-xl-12 bg-white">
            <div id="sign-in" className="auth-form form-validation">
              {errMsg && (
                <div className="login-error border p-1 my-2">{errMsg}</div>
              )}

              <form onSubmit={handleSubmit} className="form-validate">
                <h3 className="text-center mb-4 text-black">{title}</h3>

                <PasswordInput
                  id="currentPassword"
                  label={"Current Password"}
                  value={currentPassword}
                  handleChange={handleCurrentPassword}
                />

                <PasswordInput
                  id="newPassword"
                  label={"New Password"}
                  value={newPassword}
                  handleChange={handleNewPassword}
                  setFocus={setFocus}
                  isPasswordValid={isPasswordValid}
                />

                {isPasswordValid && (
                  <PasswordInput
                    id="repeatPassword"
                    label={"Repeat Password"}
                    value={repeatPassword}
                    handleChange={handleRepeatePassword}
                    isPasswordValid={isPasswordMatch}
                  />
                )}

                {showPasswordAlert && (
                  <div className="col-6 pt-3 my-4 mt-xxl-0 w-auto h-auto">
                    <div
                      className={`alert alert-${
                        isPasswordValid ? "success" : "warning"
                      } px-4 py-3 mb-0`}
                      role="alert"
                    >
                      <ul className="list-unstyled mb-0">
                        {requirements.map((requirement) => (
                          <li key={requirement.name} className="text-nowrap">
                            {requirement.value ? (
                              <Check className="text-success me-2" size={30} />
                            ) : (
                              <X className="text-danger me-2" size={30} />
                            )}
                            {requirement.description}
                          </li>
                        ))}
                      </ul>
                    </div>
                  </div>
                )}
                <div className="text-center pt-4 form-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={
                      !isPasswordValid || !isPasswordMatch || !currentPassword
                    }
                  >
                    {submitButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangePasswordForm;
