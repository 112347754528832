import {useEffect, useState} from "react";
import Plotly from "./customPlotly"
import createPlotlyComponent from "react-plotly.js/factory";

export const ScatterPlotlyTrendlineHorizontal = ({
  dataset,
  limmits
  }) => {
    const [data, setData] = useState([])
    const Plot = createPlotlyComponent(Plotly);
    useEffect(() => {

            var trace1 = []
             
            let h = [{v: 'negative', c: 'red'}, {v: 'normal', c: 'orange'}, {
                v: 'positive',
                c: 'purple'
            }]
            h.forEach(h => {
                trace1.push(
                    {
                        x: dataset.filter(e => e.status === h.v).map(e => e.receivable),
                        y: dataset.filter(e => e.status === h.v).map(e => e.performance),
                        mode: 'markers+text',
                        type: 'scatter',
                        name: h.v,
                        // text: ['A-1', 'A-2', 'A-3', 'A-4', 'A-5'],
                        textposition: 'top center',
                        marker: {size: 3, color: h.c}
                    }
                )
            })

            // var trace2 = {
            //     x: [.15, .25, .35, .45, .55],
            //     y: [0.4, 0.1, 0.7, 0.1, 0.4],
            //     mode: 'markers+text',
            //     type: 'scatter',
            //     name: 'Team B',
            //     text: ['B-a', 'B-b', 'B-c', 'B-d', 'B-e'],
            //     textposition: 'bottom center',
            //     marker: {size: 10, color: 'black'}
            // };

            let trace2 = {
                y: [0.20,0.20],
                x: [0,250],
                mode: 'lines',
                type: 'scatter',
                name: 'Area di variazione normale',
                text: ['B-a', 'B-b', 'B-c', 'B-d', 'B-e'],
                textposition: 'bottom center',
            };

            let trace3 = {
              y: [-0.20,-0.20],
              x: [0,250],
                mode: 'lines',
                type: 'scatter',
                name: 'Area di variazione normale',
                text: ['B-a', 'B-b', 'B-c', 'B-d', 'B-e'],
                textposition: 'bottom center',
            };

            let data = [trace2, trace3, ...trace1];
            setData(data)
    // eslint-disable-next-line
    }, [])


    return (
            <Plot
                style={{width: '100%'}}
                useResizeHandler={true}
                data={data}
                config={{editable: false}}
                layout={{
                    dragMode: false,
                    // showlegend: false,
                    xaxis: {
                        // tickformat: ',.0%',
                        title: 'N. preferenze ricevibili',
                        // range: [0.075, .525]
                        range: [0, 250]
                    },
                    yaxis: {
                        tickformat: ',.0%',
                        // title: 'Presenza del comportamento in azienda',
                        title: 'Performance',
                        range: [-1.0, 1.0]
                        // range: [0, 250]
                    },
                    responsive: true,
                    autosize: true,
                    width: '100%',
                    // title: 'Data Labels on the Plot'
                }}
            />
)
}
