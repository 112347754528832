export const passwordRequirements = [
    {
      name: "length",
      value: false,
      description: "Your password must have at least 8 characters",
    },
    {
      name: "capital",
      value: false,
      description: "Your password must have at least 1 uppercase letter",
    },
    {
      name: "number",
      value: false,
      description: "Your password must have at least 1 number",
    },
    {
      name: "special",
      value: false,
      description: "Your password must have at least 1 special character",
    },
  ]

export const validatePassword = (value, requirements) =>{

    let isValid = false;
    const isLengthValid = value.length >= 8;
    const hasUpperCase = /[A-Z]/.test(value);
    const hasNumber = /\d/.test(value);
    const hasSpecialChar = /[!@#$%^&*()[\]{}\\|;:'",<.>/?`~]/.test(value);

    const updatedRequirements = requirements.map((requirement) => {
      switch (requirement.name) {
        case "length":
          return { ...requirement, value: isLengthValid };
        case "capital":
          return { ...requirement, value: hasUpperCase };
        case "number":
          return { ...requirement, value: hasNumber };
        case "special":
          return { ...requirement, value: hasSpecialChar };
        default:
          return requirement;
      }
    });

    isValid = isLengthValid && hasUpperCase && hasNumber && hasSpecialChar
  
    return ([isValid, updatedRequirements])


}