import { Chart as GoogleChart } from "react-google-charts";

export const GooglePieChart = ({ color, pieData }) => {
  const data = [
    ["task", "Participation"],
    ["incomplete", pieData["total"] - pieData["avgCompleted"]],
    ["complete", pieData["avgCompleted"]],
  ];

  const options = {
    legend: { position: "none" },
    colors: ["#dbdbdb", color.dark_code],
    //colors: [color.light_code, color.dark_code],
    tooltip: { pointerEvents: "none" },
    pieSliceText: "none",
    backgroundColor: { fill: "transparent" },
    pieSliceBorderColor: "transparent",
    animation: {
      duration: 1000,
      easing: "out",
      startup: true,
    },
  };
  return (
    <div style={{ transform: "scale(1.2)" }}>
      <GoogleChart
        chartType="PieChart"
        data={data}
        options={options}
        width={"100%"}
      />
    </div>
  );
};
