import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";

import { selectActiveRole } from "../features/auth/authSlice";


const Home = () => {

  // check if logged user is an admin or an eduser
  // enduser ? ---> my report
  // admin ?  --> check adminViewMode
  // true  --> //dasboard
  // false --> got to my report
  
  /// This component is a place holder for different homepages depending on roles ///
  const activeRole = useSelector(selectActiveRole)

  return activeRole === "user_admin" ? (
    <Navigate to="/dashboard" />
  ) : (
    <Navigate to="/dashboard" />
  );
};



export default Home;
