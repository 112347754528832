import { BarChartStacked } from "./BarChartStacked";

const BarChartStackedLayout = ({
  objectArray = [0],
  full_width = false,
  title = "Title of the object",
  dataset,
  xtitle = "Title x-axis",
  ytitle = "Title y-axis",
  marker = false,
  margins = [100, 100, 10, 100],
  width,
  height,
}) => {
  //needed imports: legenda , labeled,

  var col_class = "col-lg-6 mt-3";
  if (full_width) {
    col_class = "col-lg-12 mt-3";
  }

  return (
    <>
      {objectArray.map((e, i) => (
        <div key={"object_" + i} className={col_class}>
          <div className="box">
            <div className="box-title">{title}</div>
            <BarChartStacked
              dataset={dataset}
              xtitle={xtitle}
              ytitle={ytitle}
              marker={marker}
              margins={margins}
              width={width}
              height={height}
            />
          </div>
        </div>
      ))}
    </>
  );
};

export default BarChartStackedLayout;
