function PageFooter({ title }) {
  return (
    <div className="container-fluid page-footer ">
      <div className="row">
        <div className="col-12">
          <div className="container-fluid">
            <div className="page-footer-text">{""}</div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default PageFooter;
