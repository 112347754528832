import { useLocation, Navigate, Outlet } from "react-router-dom";
import { useSelector } from "react-redux";
import { selectCurrentRoles } from "./authSlice";

const AdminGuard = ({
}) => {
  const roles = useSelector(selectCurrentRoles);
  const location = useLocation();

  return roles.includes("user_admin") ? (
    <Outlet />
  ) : (
    <Navigate to={"/"} state={{ from: location }} replace />
  );
};
export default AdminGuard;
