// in custom-plotly.js
var Plotly = require('plotly.js/lib/core');

Plotly.register([
    require('plotly.js/lib/scatterpolar'),
    require('plotly.js/lib/bar'),
    require('plotly.js/lib/violin'),
    require('plotly.js/lib/box'),
    ]);

// this includes only the scatter trace module
module.exports = Plotly;