import FormLabel from "../../components/FormLabel";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { Link } from "react-router-dom";
import { paths} from "../../config/routes";
import { useGetAccountQuery } from "../../features/account/accountSlice";
import ChangeEmail from "./components/ChangeEmail";
import ChangePassword from "./components/ChangePassword";
import { useSelector } from "react-redux";
import { selectActiveRole } from "../../features/auth/authSlice";

export default function AccountSecurity() {
  const activeRole = useSelector(selectActiveRole);

  const { data} = useGetAccountQuery();

  return (
    data && (
      <div className="container container-settings ">
      <div className="row justify-content-md-center">
        <div className="col-lg-6 ">
            
            <div className="text-center">
                  These preferences will apply only to you. <br/> For account security settings, go to{" "}
                  <Link to={paths.settings + "/account_settings"}>
                    account settings
                  </Link>
              </div>
            <div className="text-center mt-4">
              <ChangePassword />
            </div>
            
            <div className=" text-center mt-4 pb-4">
              <ChangeEmail
              currentEmail = {data.email} />
            </div>
                  
            <div className="text-center mt-4 pt-4">
              <div className="">
                <FormLabel label={"Session management"} />
              </div>
              <ButtonWithIcon 
               bgColor={"#222222"}
              text={"Close all sessions"} />
            </div>
            {activeRole === "user_admin" && (
              <div className=" text-center mt-4">
                <div className="">
                  <FormLabel label={"Permanently remove"} />
                </div>
                <ButtonWithIcon
                  bgColor={"#FE5F55"}
                  text={"Remove my account"}
                />
              </div>
            )}
          </div>
        </div>
      </div>
    )
  );
}
