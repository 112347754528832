import Menu from "../layout/Menu";
import TopNav from "../layout/TopNav";
import { useState } from "react";
import TitleBar from "../layout/components/TitleBar";
import PageFooter from "../layout/components/PageFooter";

const Page = ({ title, component, menuArray }) => {
  
  const [toggled, setToggled] = useState(false);

  return (
    <>
      <div className="d-flex">
        <Menu menuArray={menuArray} toggled={toggled} setToggled={setToggled} />
        <div className="container-fluid p-0">
          <TopNav toggled={toggled} setToggled={setToggled} />
          <TitleBar title={title} />
          {component}
          <PageFooter  />
        </div>
      </div>
    </>
  );
};

export default Page;
