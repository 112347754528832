import {
  createBrowserRouter,
  createRoutesFromElements,
  Route,
  RouterProvider,
} from "react-router-dom";
import { useSelector } from "react-redux";
import { selectActiveRole } from "./features/auth/authSlice.js";
import Login from "./pages/auth/login.js";
import ForgotPassword from "./pages/auth/forgot-password.js";
import ResetPassword from "./pages/auth/reset-password.js";
import ChangeEmail from "./pages/auth/change-email.js";
import React, { lazy, Suspense } from "react";
import Loader from "./components/Loader";
import "./styles/global.scss";
import { paths } from "./config/routes.js";
import { Modules } from "./pages/wizard/Modules";
import { Participants } from "./pages/wizard/Participants.js";
import { Presentation } from "./pages/wizard/Presentation.js";
import { Summary } from "./pages/wizard/Summary";
import AuthGuard from "./features/auth/AuthGuard";
import AdminGuard from "./features/auth/AdminGuard.js";
import ProjectsGuard from "./features/projects/ProjectsGuard.js";
import PersistLogin from "./features/auth/PersistLogin";
import Home from "./pages/Home";
import ProjectEmployees from "./pages/ProjectEmployees.js";
import { wizard } from "./config/routes.js";
import { mainMenu } from "./config/routes.js";
import { accountMenu } from "./config/routes.js";
import Page from "./pages/Page.js";
//const Home = lazy(() => import("./pages/Home"));
const NoPage = lazy(() => import("./pages/NoPage"));


const generateRoutes = (activeRole, itemArray) => {
  return itemArray.flatMap((item) => {
    if (item.roles.includes(activeRole)) {
      if (item.component) {
        return (
          <Route
            key={item.label}
            path={item.path}
            element={<Page menuArray={itemArray} title={item.label} component={item.component} />}
          />
        );
      }
      if (item.subItems) {
        return item.subItems.map((subItem) => (
          <Route
            key={`${item.path}${subItem.path}`}
            path={`${item.path}${subItem.path}`}
            element={<Page menuArray={itemArray} title={subItem.label} component={subItem.component} />}
          />
        ));
      }
    }
    return null;
  });
};


const router = (activeRole) =>
  createBrowserRouter(
    createRoutesFromElements(
      <Route path="/">
        <Route path={paths.login} element={<Login />} />
        <Route path={paths.forgot} element={<ForgotPassword />} />
        <Route path={paths.reset} element={<ResetPassword />} />
        <Route path={paths.changeEmail} element={<ChangeEmail />} />
        <Route element={<PersistLogin />}>
          <Route element={<AuthGuard />}>
            <Route element={<ProjectsGuard />}>
              <Route index element={<Home />} />
              <Route element={<AdminGuard />}>
                <Route
                  path={paths.project_employees}
                  element={<Page menuArray={mainMenu} title={"Project Settings"} component={<ProjectEmployees />} />}
                />
              </Route>
              {generateRoutes(activeRole, mainMenu)}
            </Route>
            <Route element={<AdminGuard />}>
              <Route path={wizard[0].path} element={<Modules />} />
              <Route path={wizard[1].path} element={<Participants />} />
              <Route path={wizard[2].path} element={<Presentation />} />
              <Route path={wizard[3].path} element={<Summary />} />
            </Route>
            {generateRoutes(activeRole, accountMenu)}

            <Route path="*" element={<NoPage />} />
          </Route>
        </Route>
      </Route>
    )
  );

const App = () => {
  const activeRole = useSelector(selectActiveRole);

  return (
    <Suspense fallback={<Loader />}>
      <RouterProvider router={router(activeRole)} />
    </Suspense>
  );
};

export default App;
