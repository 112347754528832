import { useEffect, useState } from "react";
import Plotly from "./customPlotly";
import createPlotlyComponent from "react-plotly.js/factory";
import { unpack } from "../functions/functions";

export const ScatterPlot = ({
  dataset,
  labeled = false,
  legenda,
  dashLines,
  showlegend = true,
  zeroline = true,
  range,
  xtitle = "Title of X-axis",
  ytitle = "Title of Y-axis",
}) => {

  const Plot = createPlotlyComponent(Plotly);
  const [data, setData] = useState([]);
  const xaxis = { title: xtitle, zeroline: zeroline };
  const yaxis = { title: ytitle, zeroline: zeroline };

  if (typeof legenda === "undefined") {
    legenda = {};
    dataset.forEach(function callback(value, index) {
      legenda[index] = `Series ${index + 1}`;
    });
  }

  if (typeof range !== "undefined") {
    xaxis["range"] = range[0];
    yaxis["range"] = range[1];
  }

  useEffect(() => {
    let data = [];
    dataset.forEach(function callback(item, index) {
      let labels = [];
      if (labeled) {
        item.forEach((row) => {
          labels.push(row.label);
        });
      }
      
      let trace = {
        type: "scatter",
        mode: "markers",
        name: legenda[index],
        text: labels,
        marker: {
          symbol: "circle",
          size: 8,
        },
      };

      data.push({
        ...trace,
        x: unpack(item, "x"),
        y: unpack(item, "y"),
      });

     


    });

    if (dashLines){
        
      const lineX = {
        type: "scatter",
        mode: "lines",
        
        x: [0, 6],
        y: [4, 4],
        line: {
          color: "black",
          dash: "dash",
          width: 0.5,
          opacity: 1.0,
        },
        showlegend: false,
      };
      data.push({
        ...lineX
      });

      const lineY = {
        type: "scatter",
        mode: "lines",
        
        x: [4, 4],
        y: [0, 6],
        line: {
          color: "black",
          dash: "dash",
          width: 0.5,
          opacity: 1.0,
        },
        showlegend: false,
      };
      data.push({
        ...lineY
      });

      const lineXY = {
        type: "scatter",
        mode: "lines",
        
        x: [0, 6],
        y: [0, 6],
        line: {
          color: "black",
          dash: "dash",
          width: 0.5,
          opacity: 1.0,
        },
        showlegend: false,
      };
      data.push({
        ...lineXY
      });


    }


    setData(data);
    // eslint-disable-next-line
  }, [dataset]);

  return (
    <div className="overflow-hidden">
      <Plot
        style={{ width: "100%", height: "100%" }}
        useResizeHandler={true}
        data={data}
        config={{ editable: false }}
        layout={{
          autosize: true,
          showlegend: showlegend,
          legend: {
            orientation: "h",
            yanchor: "bottom",
            y: 1.02,
            xanchor: "right",
            x: 1,
          },
          xaxis: xaxis,
          yaxis: yaxis,
          font: {
            size: "14",
          },
        }}
      />
    </div>
  );
};
