import { apiSlice } from "../../api/apiSlice"

export const participationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getParticipation: builder.query({
            query: (id) => {
                if (id) return `report/analytics/participation/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetParticipationQuery
} = participationApiSlice 