import { useId } from "react";
import { useState } from "react";
import { Eye, EyeSlash } from "react-bootstrap-icons";

export const PasswordInput = ({
  id,
  label = "",
  buttonType = "button",
  value,
  handleChange,
  setFocus,
  isPasswordValid,
}) => {

  const [showPassword, setShowPassword] = useState(false);

  const randonId = useId();

  if (!id) id = randonId;


  return (
    <>
      <div className="form-group mb-3">
        <label htmlFor="password" className="mb-1">
          <strong>{label}</strong>
        </label>
        <div className="input-group d-flex">
          <span className="input-group-text border-0">
            <button
            tabIndex = {"-1"} 
              type={buttonType}
              className="btn btn-sm"
              id={`eye-button-${id}`}
              onClick={(e) => {
                setShowPassword(!showPassword);
              }}
            >
              {showPassword ? <Eye /> : <EyeSlash />}
            </button>
          </span>
          <input
            {...(setFocus && {
              onBlur: (e) => {
                setFocus(false);
              },
              onFocus: (e) => {
                setFocus(true);
              },
            })}
            type={!showPassword ? "password" : "text"}
            className={`form-control rounded mt-1 ${
              isPasswordValid === undefined ? "" : isPasswordValid ?  "is-valid" : "is-invalid"
            }`}
            placeholder="Type your password"
            value={value}
            onChange={(e) => {handleChange(e)}}
            id={id}
          />
        </div>
      </div>
    </>
  );
};
