import { useEffect, Fragment } from "react";
import { useNavigate } from "react-router-dom";
import ModalComponent from "../../features/modal/Modal";
import { SelectIsOpen, openModal } from "../../features/modal/modalSlice";
import { PrevNext } from "./components/PrevNext";
import FormLabel from "../../components/FormLabel";
import TextareaAutosize from "react-textarea-autosize";
import { useSelector, useDispatch } from "react-redux";
import { selectAllModules } from "../../features/auth/authSlice";
import {
  selectAllWizard,
  updateTitle,
  updateDescription,
  setModules,
  toggleModuleSelect,
} from "../../features/wizard/wizardSlice";
import { wizard } from "../../config/routes";

export const Modules = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const isOpen = useSelector(SelectIsOpen);
  const [userModules, systemModules] = useSelector(selectAllModules);
  const wizardData = useSelector(selectAllWizard);

  const modalFunction = () => {
    window.open("https://umana-analytics.com/prodotto/pay-per-use/", "_blank");
  };
  
  const selectedModules = (data) => {
    let count = 0;
    data.forEach((module) => (module.selected === true ? count++ : count));
    return count;
  };

  useEffect(() => {
    if (!wizardData.modules.length) {
      let initialState = [];
      systemModules.forEach((module) => {
        if (userModules.includes(module.code)) {
          initialState.push({
            ...module,
            disabled: false,
            selected: true,
          });
        } else {
          initialState.push({
            ...module,
            disabled: true,
            selected: false,
          });
        }
      });
      if (initialState.length > 0) {
        dispatch(setModules(initialState))
      }
    }
  }, [wizardData]);

  return (
    <>

     <ModalComponent
title={"Licence is missing"}
bodyText={`For this module a specific license is requirend.
         Please visit the online store for more information.`}
modalFunction={modalFunction}
isOpen={isOpen}
/>
<div className="container container-wizard">
<PrevNext
nextClicked={() => {
  navigate(wizard[1].path);
}}
step={"1 of 4"}
nextDisabled={selectedModules(wizardData.modules) > 0 ? false : true}
/>


<div className="row align-items-center ">
  <div className="col-lg-6 color-blue-dark m-auto mt-5 mt-lg-0">
    <div className="row text-center color-blue-dark m-auto mt-5 mt-lg-0">
      <h3>
        <b> Project Description </b>
      </h3>
    </div>
    <div className="pt-4">
      <FormLabel label={"Project Title"} />
      <input
        id="projectTitle"
        value={wizardData.title || ""}
        onChange={(e) => {
          dispatch(updateTitle(e.target.value));
        }}
        type="text"
      />
    </div>
    <div className="pt-3">
      <FormLabel label={"Project Description"} />
      <TextareaAutosize
        id="projectDescription"
        value={wizardData.description || ""}
        className="box"
        type="text"
        style={{
          border: "solid 2px #05438A",
          borderRadius: "30px",
          padding: "20px 20px",
          width: "100%",
          background: "transparent",
          display: "block",
          color: "#333333",
          fontSize: ".95rem",
        }}
        minRows={5}
        onChange={(e) => {
          dispatch(updateDescription(e.target.value));
        }}
      />
    </div>
  </div>

  <div className="col-lg-5 text-start">
    <div
      className="color-blue-dark m-auto mt-5 mt-lg-0"
      style={{ maxWidth: 500 }}
    >
      <div className="row">
        <h3>
          <b>Select your modules </b>
        </h3>
      </div>

      <div className="row">
        {wizardData.modules?.length > 0 &&
          wizardData.modules.map((module, i) => {
            return (
              <Fragment key={"module" + i}>
                {!module.disabled && (
                  <div className="form-check checkbox-xl">
                    <input
                      id={"module_" + i}
                      type="checkbox"
                      checked={module.selected}
                      disabled={module.disabled}
                      className="form-check-input"
                      onChange={() => {
                        dispatch(toggleModuleSelect({ index: i }));
                      }}
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"module_" + i}
                    >
                      {module.name}
                    </label>
                  </div>
                )}
                {module.disabled && (
                  <div
                    role="button"
                    onClick={() => {
                      dispatch(openModal());
                    }}
                    className="form-check checkbox-xl"
                  >
                    <input
                      id={"module_" + i}
                      type="checkbox"
                      checked={module.selected}
                      disabled={module.disabled}
                      className="form-check-input"
                    />
                    <label
                      className="form-check-label"
                      htmlFor={"module_" + i}
                    >
                      {module.name}
                    </label>
                  </div>
                )}
              </Fragment>
            );
          })}
      </div>
    </div>
  </div>
</div>
</div>
     
    </>
  );
};
