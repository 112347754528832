import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  title: "New Project",
  description: "Add here a description for the new project to be created",
  modules: [],
  participants: [],
  presentation: false,
};

const number_of_participants = (participants) => {
  let count = 0;
  participants.forEach((p) => {
    if (p.EMAIL?.includes("@")) count++;
  });
  return count;
};

export const wizardSlice = createSlice({
  name: "wizard",
  initialState,
  reducers: {
    updateTitle: {
      reducer(state, action) {
        state.title = action.payload;
      },
    },
    updateDescription: {
      reducer(state, action) {
        state.description = action.payload;
      },
    },
    setModules: {
      reducer(state, action) {
        state.modules = action.payload;
      },
    },
    toggleModuleSelect: {
      reducer(state, action) {
        state.modules[action.payload.index].selected =
          !state.modules[action.payload.index].selected;
      },
    },
    togglePresentation: {
      reducer(state, action) {
        state.presentation = !state.presentation;
      },
    },
    addParticipants: {
      reducer(state, action) {
        state.participants.push(action.payload);
      },
    },
    setParticipants: {
      reducer(state, action) {
        state.participants = action.payload;
      },
      prepare: (data) => {
        data.forEach((obj) => {
          Object.keys(obj).forEach((key) => {
            obj[key] = obj[key] === undefined && key != "EMAIL" ? "" : obj[key];
          });
        });
        return {
          payload: data,
        };
      },
    },
    resetParticipants: {
      reducer(state) {
        state.participants = [];
      },
    },
    updateParticipants: {
      reducer(state, action) {
        state.participants[action.payload.index] = action.payload.participant;
      },
    },
    deleteParticipants: {
      reducer(state, action) {
        state.partecipants = state.participants.filter(
          (e, i) => action.payload.indexOf(i) === -1
        );
      },
    },
    clearWizard: {
      reducer(state) {
        state.title = "New Project";
        state.description =
          "Add here a description for the new project to be created";
        state.participants = [];
        state.modules = [];
        state.presentation = false;
      },
    },
  },
});

export const {
  updateTitle,
  updateDescription,
  setModules,
  toggleModuleSelect,
  togglePresentation,
  addParticipants,
  setParticipants,
  resetParticipants,
  updateParticipants,
  deleteParticipants,
  clearWizard,
} = wizardSlice.actions;

export const selectAllWizard = (state) => state.wizard;
export const selectAllModules = (state) => state.wizard.modules;
export const selectPresentation = (state) => state.wizard.presentation;

export const selectParticipants = (state) => state.wizard.participants;
export const selectNumberParticipants = (state) => {
  return number_of_participants(state.wizard.participants);
};

export default wizardSlice.reducer;
