import { useId } from "react";

export const EmailInput = ({
  id,
  disabled = false,
  label = "",
  value,
  handleChange,
}) => {
  const randonId = useId();

  if (!id) id = randonId;

  return (
    <>
      <div className="form-group mb-3">
        <label htmlFor="email" className="mb-1">
          <strong>{label}</strong>
        </label>
        <input
          type="email"
          disabled={disabled}
          className={"form-control"}
          placeholder="Type a email address"
          value={value}
          onChange={(e) => handleChange(e)}
          id={id}
        />
      </div>
    </>
  );
};
