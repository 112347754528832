import { apiSlice } from "../../api/apiSlice"

export const peopleDiscoveryApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getPeopleDiscovery: builder.query({
            query: (id) => {
                if (id) return `report/LHIPD/people-discovery/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
            transformResponse: (res) => res.sort((a, b) => b.impact - a.impact),
        })
    })
})

export const {
    useGetPeopleDiscoveryQuery
} = peopleDiscoveryApiSlice 