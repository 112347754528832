import { useEffect, useState } from "react";
import { useWindowSize } from "../../utils/windowSize";
import Plotly from "./customPlotly";
import createPlotlyComponent from "react-plotly.js/factory";

export const ScatterPlotlyTrendline = ({ dataset, gradients }) => {
  const [data, setData] = useState([]);
  const size = useWindowSize();
  const Plot = createPlotlyComponent(Plotly);

  useEffect(() => {
    let trace1 = [];

    {
      /* old version with low points
            var h = [{v: 'negativo', c: 'red'}, {v: 'basso', c: 'blue'}, {v: 'normale', c: 'orange'}, {
                v: 'positivo',
                c: 'purple'
            }]
            */
    }
    let h = [
      { v: "negative", c: "red" },
      { v: "normal", c: "orange" },
      {
        v: "positive",
        c: "purple",
      },
    ];
    h.forEach((h) => {
      trace1.push({
        x: dataset.filter((e) => e.status == h.v).map((e) => e.receivable),
        y: dataset.filter((e) => e.status == h.v).map((e) => e.received),
        mode: "markers+text",
        type: "scatter",
        name: h.v,
        // text: ['A-1', 'A-2', 'A-3', 'A-4', 'A-5'],
        textposition: "top center",
        marker: { size: 3, color: h.c },
      });
    });

    let trace2 = {
      y: [0, 250 * gradients[2]],
      x: [0, 250],
      mode: "lines",
      type: "scatter",
      name: "UNL",
      text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
      textposition: "bottom center",
    };

    let trace3 = {
      y: [0, 250 * gradients[0]],
      x: [0, 250],
      mode: "lines",
      type: "scatter",
      name: "LNL",
      text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
      textposition: "bottom center",
    };

    let trace4 = {
      y: [0, 250 * gradients[1]],
      x: [0, 250],
      mode: "lines",
      type: "scatter",
      name: "Punteggio atteso",
      text: ["B-a", "B-b", "B-c", "B-d", "B-e"],
      textposition: "bottom center",
    };

    let data = [trace2, trace3, trace4, ...trace1];
    setData(data);
  }, []);

  return (
    <Plot
      style={{ width: "100%" }}
      useResizeHandler={true}
      data={data}
      config={{ editable: false }}
      layout={{
        dragMode: false,
        // showlegend: false,
        xaxis: {
          // tickformat: ',.0%',
          title: "N. preferenze ricevibili",
          // range: [0.075, .525]
          range: [0, 250],
        },
        yaxis: {
          // tickformat: ',.0%',
          // title: 'Presenza del comportamento in azienda',
          title: "Preferenze ottenute",
          // range: [0, 1]
          range: [0, 250],
        },
        responsive: true,
        autosize: true,
        width: "100%",
        // title: 'Data Labels on the Plot'
      }}
    />
  );
};
