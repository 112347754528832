import { apiSlice } from "../../api/apiSlice"

export const identificationApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getIdentification: builder.query({
            query: (id) => {
                if (id) return `report/JSLIC/identification/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetIdentificationQuery
} = identificationApiSlice 