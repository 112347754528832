import { ButtonWithIcon } from "../../../components/ButtonWithIcon";
import ModalComponent from "../../../features/modal/Modal";
import ChangePasswordForm from "./ChangePasswordForm";
import { useState } from "react";

export default function ChangePassword() {
  const [isOpen, setIsOpen] = useState(false);

  return (
    <>
      <ModalComponent
        title={"Change password"}
        bodyText={<ChangePasswordForm />}
        size="lg"
        setIsOpen={setIsOpen}
        isOpen={isOpen}
      />
      <div className="col-md-auto mt-3 mt-md-0">
        <ButtonWithIcon
          disabled={false}
          onClick={(e) => {
            setIsOpen(true);
          }}
          text={"Change password"}
        />
      </div>
    </>
  );
}
