import OverallLayout from "../components/OverallLayout";
import FilterLayout from "../components/FilterLayout";
import RadarLayout from "../components/graph/RadarLayout";
import Loader from "../components/Loader";
import OverlayCustom from "../components/OverlayCustom";
import { useSelector } from "react-redux";
import { selectActiveRole } from "../features/auth/authSlice";
import { selectCurrentModules } from "../features/auth/authSlice";
import { useGetDashboardQuery } from "../features/report/dashboardApiSlice";
import { SelectIsOpen } from "../features/modal/modalSlice";
import ModalComponent from "../features/modal/Modal";
import {
  SelectCurrentFilters,
  SelectCurrentProject,
  SelectAllFilters,
} from "../features/projects/projectSlice";
import FilterBar from "../layout/FilterBar";
import { splitData } from "../components/functions/functions";

const Dashboard = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const selectedModules = useSelector(selectCurrentModules);
  const isOpen = useSelector(SelectIsOpen);
  const activeRole = useSelector(selectActiveRole);

  const { data, isLoading, isSuccess, isError, error } =
    useGetDashboardQuery(selectedProject);

  const OverlayComponent = () => {
    return (
      <OverlayCustom
        header={"Longitudinal analysis"}
        body={
          "The longitudinal analysis will be possible as soon as a second administration has been carried out for at least one module"
        }
        comp={
          <div className="card overlayCustom mt-1 text-center bg-gray-light round-4">
            <span className="text-muted text-nowrap">
              {" "}
              Longitudinal analysis
            </span>
          </div>
        }
      />
    );
  };

  const modalFunction = () => {
    window.open(
      "https://umana-analytics.com/umana-analytics-store-copy/",
      "_blank"
    );
  };

  function filterData(data, filter, split = true) {
    if (!data) return null;

    let filter_value = filter === "costrutto" ? "overall" : filter;

    if (split) {
      return splitData(data[filter_value]);
    } else {
      return data[filter_value];
    }
  }

  const handleFilterCheck = () => {
    if (selectedFilters.length === 0) {
      return false;
    }
    if (selectedFilters[0] === "Overall") {
      return false;
    }
    return true;
  };

  const hasModule = (module) => {
    if (selectedModules.includes(module)) {
      return true;
    } else {
      return false;
    }
  };

  let radarData = null;
  let pieData = null;
  let starData = null;

  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    let filterValue = filterArray[selectedFilters[0]];
    radarData = filterData(data.radarData[0], filterValue, true);
    starData = filterData(data.starData, filterValue, true);
    pieData = filterData(data.pieData, filterValue, false);
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      
      <ModalComponent
        title={"Licence is missing"}
        bodyText={`For this analysis a specific license is requirend.
                 Please visit the online store for more information.`}
        modalFunction={modalFunction}
        isOpen={isOpen}
      />

      <div className="container-fluid">
        <div className="row">
        <FilterBar selectedFilters={selectedFilters} />
        </div>
        <div className="row"> 
          <div className="col-12 pb-4">
            <div className="container">
              {handleFilterCheck()
                ? starData &&
                  pieData &&
                  activeRole == "user_admin" && (
                    <FilterLayout
                      starData={starData}
                      pieData={pieData}
                      hasModule={hasModule}
                      OverlayComponent={OverlayComponent}
                    />
                  )
                : starData &&
                  pieData &&
                  activeRole == "user_admin" && (
                    <OverallLayout
                      stardata={starData}
                      pieData={pieData}
                      hasModule={hasModule}
                      OverlayComponent={OverlayComponent}
                    />
                  )}

              {activeRole == "user_admin"
                ? radarData && (
                    <RadarLayout
                      full_width={true}
                      title={""}
                      scales={data.radarData[1].theta}
                      benchmark={data.radarData[0].benchmark}
                      radarData={radarData[0]}
                      labels={radarData[1]}
                      OverlayComponent={OverlayComponent}
                    />
                  )
                : radarData && (
                    <RadarLayout
                      full_width={true}
                      title={""}
                      scales={data.radarData[1].theta}
                      myResults={data.radarData[0]["user_results"]}
                      radarData={radarData[0]}
                      labels={radarData[1]}
                      OverlayComponent={OverlayComponent}
                    />
                  )}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Dashboard;
