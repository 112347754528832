import {ScatterPlot} from "./ScatterPlot";


const ScatterStackedLayout = ({
  dataset,
  titles = {
    "title" : "Title",
    "xtitle": "x-axis",
    "ytitle": "y-axis"
  },
  legenda,
  dashLines = false,
  showlegend,
  labeled,
  range,
  zeroline,
  full_width = false,

}) => {

  let col_class = full_width ? 'col-lg-12 mt-4' : 'col-lg-6 mt-4';

    return (
      <>
      {dataset &&

        <div className={col_class} >
        <div className="box">
        <div className="box-title">{titles.title ? titles.title : ""}</div>
            <ScatterPlot
            dataset = {dataset}
            title   = {''}
            xtitle  = {titles.xtitle ? titles.xtitle : ""}
            ytitle  = {titles.ytitle ? titles.ytitle : ""}
            legenda = {legenda}
            dashLines = {dashLines}
            showlegend = {showlegend}
            range  = {range}
            labeled = {labeled}
            zeroline = {zeroline}
            />
        </div>
    </div>

      }
     </>         

    )
}

export default ScatterStackedLayout
