import { apiSlice } from "../../api/apiSlice"

export const trustApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getTrust: builder.query({
            query: (id) => {
                if (id) return `report/TI/trust/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetTrustQuery
} = trustApiSlice 