import ProfilePicture from "../../components/ProfilePicture";
import { ButtonWithIcon } from "../../components/ButtonWithIcon";
import { useEffect, useState } from "react";
import {
  useGetAccountQuery,
  useUpdateAccountMutation,
} from "../../features/account/accountSlice";
import { locales } from "../../utils/locales";
import FormLabel from "../../components/FormLabel";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export default function Account() {
  const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary m-2",
        cancelButton: "btn btn-secondary m-2",
      },
      buttonsStyling: false,
    })
  );

  const [accountData, setAccountData] = useState({
    name: "",
    surname: "",
    email: "",
    timezone: "",
    prefix: "",
    locale: locales[0].id,
    telephone: "",
  });

  const { data, isLoading } = useGetAccountQuery("AccountData", {});

  const [UpdateAccount] = useUpdateAccountMutation();

  // TODO: make sure this is the correct approach. I think setUserData should be placed in a react query callback but what i tried doesn't work :(
  useEffect(() => {
    if (data) {
      setAccountData(data);
    }
  }, [data]);

  const responseModal = (input, error = false) => {
    if (!error) {
      MySwal.fire({
        //title: <h3>{input.message.title}</h3>,
        html: <i>{input.message}</i>,
        showCancelButton: false,
        //cancelButtonText: "Home",
        confirmButtonText: "Close",
        icon: "success",
      });
    } else
      MySwal.fire({
        title: <h3>{input.data.message}</h3>,
        html: <i>{input.data.data}</i>,
        confirmButtonText: "close",
        icon: "error",
      });
  };

  const handleChange = (e) => {
    e.preventDefault();
    const { name, value } = e.target;
    setAccountData({ ...accountData, [name]: value });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    async function asyncCall() {
      await UpdateAccount(accountData)
        .unwrap()
        .then((payload) => {
          responseModal(payload);
        })
        .catch((error) => {
          responseModal(error, (error = true));
        });
    }
    asyncCall();
  };

  return (
    <>
      {accountData && (

          <div className="container container-settings ">
            <div className="row justify-content-md-center ">
              <div className="col-lg-6">
                <div className="text-center" style={{ height: 100 }}>
                  <ProfilePicture thumbnail={false} />
                </div>
                <div className="text-center mt-4">
                  <ButtonWithIcon
                    type="button"
                    text={"Change profile picture"}
                  />
                </div>

                <div className="pt-4">
                  <FormLabel label={"Name"} />
                  <input
                    value={accountData.name || ""}
                    name="name"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
                <div className="pt-4">
                  <FormLabel label={"Surname"} />
                  <input
                    value={accountData.surname || ""}
                    name="surname"
                    onChange={handleChange}
                    type="text"
                  />
                </div>
                <div className="pt-4">
                  <FormLabel label={"Language"} />
                  <select
                    value={accountData.locale || ""}
                    name="locale"
                    onChange={handleChange}
                  >
                    {locales.map((e) => (
                      <option key={e.id} value={e.id}>
                        {e.label}
                      </option>
                    ))}
                  </select>
                </div>
                <div className="pt-4">
                  <FormLabel label={"Telephone number"} />
                  <p>
                    We may use this telephone number to contact you regarding
                    security events and sending SMS on workflows. See our
                    privacy policy for further information.
                  </p>
                  <div className="row">
                    <div className="col-2">
                      <input
                        name="prefix"
                        disabled={true}
                        onChange={handleChange}
                        placeholder={"+39"}
                        type="text"
                      />
                    </div>
                    <div className="col-10">
                      <input
                        name="telephone"
                        value={accountData.telephone || ""}
                        onChange={handleChange}
                        type="text"
                      />
                    </div>
                  </div>
                </div>
                <div className="pt-4">
                  <ButtonWithIcon
                    text={"save"}
                    onClick={handleSubmit}
                    disabled={isLoading}
                    type="submit"
                  />
                </div>
              </div>
            </div>
          </div>

      )}
    </>
  );
}
