import { apiSlice } from "../../api/apiSlice";
import { setCredentials } from "./authSlice";

export const authApiSlice = apiSlice.injectEndpoints({
  endpoints: (builder) => ({
    login: builder.mutation({
      query: (credentials) => ({
        url: "auth/login",
        method: "POST",
        body: { ...credentials },
      }),
    }),
    refresh: builder.mutation({
      query: () => ({
        url: "auth/refresh",
        method: "GET",
      }),
      async onQueryStarted(arg, { dispatch, queryFulfilled }) {
        // `onStart` side-effect
        try {
          const { data } = await queryFulfilled;
          dispatch(setCredentials({ ...data }));
        } catch (err) {
          console.log(err);
        }
      },
    }),

    logout: builder.mutation({
      query: () => ({
        url: "auth/logout",
        method: "POST",
        body: {},
      }),
    }),

    forgot: builder.mutation({
      query: (email) => ({
        url: "auth/forgot-password",
        method: "POST",
        body: { email },
      }),
    }),

    reset: builder.mutation({
      query: ([token, password]) => ({
        url: "auth/reset-password",
        method: "POST",
        body: { token: token, password: password },
      }),
    }),

    changePassword: builder.mutation({
      query: ([currentPassword, newPassword]) => ({
        url: "auth/change-password",
        method: "POST",
        body: { currentPassword: currentPassword, newPassword: newPassword },
      }),
    }),
    
    changeEmail: builder.mutation({
      query: ([currentEmail, newEmail, password]) => ({
        url: "auth/request-email-change",
        method: "POST",
        body: { 
          currentEmail: currentEmail,
          newEmail: newEmail,
          password: password },
      }),
    }),
    confirmChange: builder.mutation({
      query: (token) => ({
        url: "auth/change-email",
        method: "POST",
        body: { token },
      }),
    }),
  }),
});

export const {
  useLoginMutation,
  useRefreshMutation,
  useLogoutMutation,
  useForgotMutation,
  useResetMutation,
  useChangeEmailMutation,
  useChangePasswordMutation,
  useConfirmChangeMutation
} = authApiSlice;
