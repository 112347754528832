import React from "react";
import { GlobalFilter } from "./GlobalFilter";

const TableToolbar = (props) => {
  const { setGlobalFilter, globalFilter } = props;
  return (
    <>
      <div className="d-flex justify-content-between">
        <span></span>
        <span>
          <GlobalFilter filter={globalFilter} setFilter={setGlobalFilter} />
        </span>
      </div>
    </>
  );
};

export default TableToolbar;
