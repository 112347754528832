import { RadarPlotly } from "./RadarPlotly";
import { PieColors } from "../../utils/PieColors";

const RadarLayout = ({
  full_width = false,
  radarData,
  benchmark,
  myResults,
  labels,
  scales,
  OverlayComponent,
}) => {
  const pieColors = [
    PieColors.lightBlue,
    PieColors.green,
    PieColors.orange,
    PieColors.blue,
    PieColors.red,
    PieColors.copper,
    PieColors.goldgreen,
    PieColors.coral,
    PieColors.kelly,
    PieColors.chocolate,
    PieColors.scarlet,
    PieColors.mustard,
    PieColors.olive,
  ];

  let col_class = "col-lg-8 pt-4";
  if (full_width) {
    col_class = "col-lg-12 pt-4";
  }

  return (
    <div className={col_class}>
      <div className="box">
        <RadarPlotly
          dataset={radarData}
          scales={scales}
          labels={labels}
          benchmark={benchmark}
          myResults={myResults}
          colorarray={pieColors}
        />
        <OverlayComponent />
      </div>
    </div>
  );
};

export default RadarLayout;
