import { apiSlice } from "../../api/apiSlice"

export const dashboardApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getDashboard: builder.query({
            query: (id) => {
                if (id) return `report/dashboard/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetDashboardQuery
} = dashboardApiSlice 