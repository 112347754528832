import impact_mock_data from "../../mockdata/impact_mock_data";
import LongitudinalLayout from "../graph/LongitudinalLayout";

const LongitudinalAnalysis = () => {
  // Function to generate a random number from a normal distribution
  function randomNormal(mean, sd) {
    let u1 = Math.random();
    let u2 = Math.random();
    let randStdNormal =
      Math.sqrt(-2.0 * Math.log(u1)) * Math.sin(2.0 * Math.PI * u2);
    return mean + sd * randStdNormal;
  }
  const data = [];
  data.push(impact_mock_data[0].scale_data[3]);
  data.push(impact_mock_data[0].scale_data[2]);
  data.push(impact_mock_data[0].scale_data[1]);
  data.push(impact_mock_data[0].scale_data[0]);

  return (
    <>
      {data && (
        <div className="container">
          <div className="row">
            <LongitudinalLayout
              data={data}
              time_points={impact_mock_data[0].time_points}
              title={"Longitudinal Analysis: Workplate Trust Overall"}
            />
          </div>
        </div>
      )}
    </>
  );
};

export default LongitudinalAnalysis;
