import { apiSlice } from "../../api/apiSlice"

export const wellbeingApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWellbeing: builder.query({
            query: (id) => {
                if (id) return `report/WI/wellbeing/${id}`;
                throw new Error("ID is undefined");
            },   
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetWellbeingQuery
} = wellbeingApiSlice 