import { useNavigate } from "react-router-dom";
import { paths } from "../config/routes";
import { useDispatch, useSelector } from "react-redux";
import { getCurrentLoginStatus, setLogout } from "../features/auth/authSlice";
import { useLogoutMutation } from "../features/auth/authApiSlice";
import { useState, useRef } from "react";
import ProfilePicture from "../components/ProfilePicture";
import { Dropdown } from "react-bootstrap";
import {
  selectCurrentRoles,
  selectActiveRole,
  setActiveRole,
  selectCurrentUSerName,
} from "../features/auth/authSlice";
import {
  updateFilters,
  selectAllProjects,
  SelectCurrentProject,
  updateProject
} from "../features/projects/projectSlice";

import { List } from "react-bootstrap-icons";
import Form from "react-bootstrap/Form";

export default function TopNav({ toggled, setToggled }) {
  const loginStatus = useSelector(getCurrentLoginStatus);
  const currentRoles = useSelector(selectCurrentRoles);
  const activeRole = useSelector(selectActiveRole);
  const [logout] = useLogoutMutation();
  
  const [errMsg, setErrMsg] = useState("");
  const nav = useNavigate();
  const userName = useSelector(selectCurrentUSerName);
  const data = { name: userName[0], surname: userName[1] };

  const projects = useSelector(selectAllProjects);
  const currentProject = useSelector(SelectCurrentProject);
  const dispatch = useDispatch();


  const roleText = activeRole === "user_admin" ? "Admin" : "Trustie User";
  const toggleRef = useRef(null);

  const handleToggleClick = () => {
    if (toggleRef.current) {
      toggleRef.current.click(); // Trigger the click event on the Dropdown.Toggle
    }
  };

  const getSelectedProject = () => {
    
    return projects.filter((item) => item.id === currentProject)[0].id;
  };
  const handleProjectChange = (id) =>{
   
    console.log(id)
    dispatch(updateProject(
      parseInt(id)
    ))
    //now here the logic to update the project


  }


  const handleLogout = async (e) => {
    e.preventDefault();

    try {
      //dispatch a function which performs the logout
      await logout().unwrap();
      dispatch(setLogout());
      dispatch({ type: "RESET" }); //calls the root reducer with RESET as action!
      nav("/login");
    } catch (err) {
      if (!err?.originalStatus) {
        // isLoading: true until timeout occurs
        setErrMsg("No Server Response");
      } else if (err.originalStatus === 400) {
        setErrMsg("General error");
      } else if (err.originalStatus === 401) {
        setErrMsg("Unauthorized");
      } else {
        setErrMsg("Logout Failed");
      }
    }
  };

  return (
    <>
      <div className="container-fluid py-2 ">
        <div className="row justify-content-between align-items-center">
          <div className="col-auto">
            <span>
              {" "}
              {/*hamburger menu*/}
              <List
                className="MenuIcon"
                role="button"
                size={32}
                onClick={() => setToggled(!toggled)}
              />
            </span>
            <div>
              {/* selection function to switch project */}
              <Form.Select 
              value={getSelectedProject()}
              onChange ={(e)=>{handleProjectChange(e.target.value)}}  
              >
                {projects.map((project) => {
                  console.log(project.id)
                  return <option key={project.id} value={project.id}>{project.name}</option>;
                })}
              </Form.Select>
            </div>
          </div>
          {loginStatus && (
            <div className="col-auto">
              <div className="row align-items-center ">
                <div className="col-auto p-0 ">
                  <div role="button" onClick={handleToggleClick}>
                    <ProfilePicture thumbnail={true} />
                  </div>
                </div>

                <div className="col-auto p-0">
                  <Dropdown>
                    <Dropdown.Toggle
                      ref={toggleRef}
                      style={{
                        background: "transparent",
                        border: "none",
                      }}
                    ></Dropdown.Toggle>

                    <Dropdown.Menu>
                      <Dropdown.Item
                        onClick={() => {
                          nav(paths.settings + "/profile");
                        }}
                      >
                        Account
                      </Dropdown.Item>
                      {["user_admin", "trustie_user"].every((i) =>
                        currentRoles.includes(i)
                      ) && (
                        <Dropdown.Item
                          onClick={() => {
                            activeRole === "user_admin"
                              ? dispatch(setActiveRole("trustie_user"))
                              : dispatch(setActiveRole("user_admin"));
                            dispatch(updateFilters("Overall"));
                            nav("/");
                          }}
                        >
                          {activeRole === "user_admin"
                            ? "View as End User"
                            : "View as Admin"}
                        </Dropdown.Item>
                      )}

                      <Dropdown.Item onClick={handleLogout}>
                        Logout
                      </Dropdown.Item>
                    </Dropdown.Menu>
                  </Dropdown>
                </div>
                <div className="col-auto p-0">
                  {data && (
                    <>
                      <div
                        className="row"
                        style={{ marginRight: "24px" }}
                        role="button"
                        onClick={handleToggleClick}
                      >
                        <span style={{ fontWeight: "bold" }}>
                          {data.name} {data.surname}
                        </span>
                      </div>
                      <div className="row">
                        <small>{roleText}</small>
                      </div>
                    </>
                  )}
                </div>
              </div>
            </div>
          )}
        </div>
      </div>
    </>
  );
}
