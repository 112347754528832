import { useSelector } from "react-redux";
import Stars from "./graph/Stars";
import PieChartLayout from "./graph/PieChartLayout";
import { PieColors } from "../utils/PieColors";
import {
  selectAllStarGroups,
  selectAllModules,
} from "../features/auth/authSlice";

const OverallLayout = ({
  stardata,
  pieData,
  hasModule,
  projectdata,
  OverlayComponent,
}) => {
  const starGroups = useSelector(selectAllStarGroups);
  const modules = useSelector(selectAllModules);

  function getStarValue(data) {
    if (!stardata[0][0]) return 0
    let test = stardata[0][0]
      .filter((e) => e.star_group === data.id)
      .map((obj) => obj.value);
    if (test[0]) return test[0];
    else return 0;
  }

  return (
    <div className="row py-3">
      <div className="col-lg-12">
        <div className="row">
          <div className="col-xl-8">
            <div className="box h-100">
              {modules[1].map((module, i) => {
                const starData = starGroups.filter(
                  (e) => e.module === module.code
                );
                if (starData.length === 0) {
                  return null; // Use null for empty rendering
                } else {
                  return (
                    <div
                      key={module.code}
                      className="mt-2 border border-primary rounded-4"
                    >
                      <div className="px-2 row align-items-center">
                        <div className="col-md-9 justify-content-center">
                          {starData.map((element, index) => (
                            <Stars
                              color={"defaultBlue"}
                              enabled={hasModule(element.module)}
                              points={getStarValue(element)}
                              title={element.name}
                            />
                          ))}
                        </div>
                      </div>
                    </div>
                  );
                }
              })}
              <OverlayComponent />
            </div>
          </div>

          <div className="col-xl-4">
            <div className="box mt-xl-0 h-100 d-flex align-items-top flex-column justify-content-center">
              <PieChartLayout
                color={PieColors.lightBlue}
                chart_id={1}
                pieData={pieData[0]}
                perc={true}
                title={true}
              />
              <OverlayComponent />
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};
export default OverallLayout;
