import { useEffect, useState } from "react";
import Plotly from "./customPlotly";
import createPlotlyComponent from "react-plotly.js/factory";

export const RadarPlotly = ({
  dataset,
  benchmark,
  myResults,
  labels,
  scales,
  colorarray,
}) => {
  const [data, setData] = useState([]);
  const Plot = createPlotlyComponent(Plotly);

  const benchmark_trace = {
    type: "scatterpolar",
    fill: "none",
    line: { dash: "dash", color: "black" },
  };

  const my_results_trace = {
    type: "scatterpolar",
    fill: "none",
    line: { dash: "dash", color: "green" },
  };

  useEffect(() => {
    const input = labels.map((label, i) => {
      let theta = [];
      let r = [];

      const trace = {
        type: "scatterpolar",
        fill: "none",
        line: { color: colorarray[i].dark_code },
      };

      dataset[i].forEach((element, i) => {
        r.push(element.avg);
        theta.push(scales[element.scale_id]);
      });

      // add last first value to the end of the lists to close the radar graph
      r.push(r[0]);
      theta.push(theta[0]);

      return {
        ...trace,
        name: label,
        r: r,
        theta: theta,
      };
    });

    // add benchmark data if available
    if (benchmark) {
      let theta = [];
      let r = [];

      benchmark.forEach((element, i) => {
        r.push(element.avg);
        theta.push(scales[element.scale_id]);
      });
      // add last first value to the end of the lists to close the radar graph
      r.push(r[0]);
      theta.push(theta[0]);

      input.push({
        ...benchmark_trace,
        name: "Benchmark",
        r: r,
        theta: theta,
      });
    }


    // add benchmark data if available
    if (myResults) {
      let theta = [];
      let r = [];

      myResults.forEach((element, i) => {
        r.push(element.avg);
        theta.push(scales[element.scale_id]);
      });
      // add last first value to the end of the lists to close the radar graph
      r.push(r[0]);
      theta.push(theta[0]);

      input.push({
        ...my_results_trace,
        name: "My Results",
        r: r,
        theta: theta,
      });
    }


    setData(input);
  }, [dataset]);

  return (
    <Plot
      useResizeHandler={true}
      style={{ width: "100%", height: "100%" }}
      data={data}
      config={{ editable: false }}
      layout={{
        autosize: true,
        height: 800,
        automargin: true,
        font: {
          size: "14",
        },
        legend: {
          orientation: "h",
          yanchor: "bottom",
          y: 1.3,
          xanchor: "right",
          x: 1,
        },
        polar: {
          angularaxis: {
            tickprefix: "  ",
            ticksuffix: "   ",
            tickangle: 0,
            ticklen: 10,
          },
          radialaxis: {
            visible: true,
            range: [0, 6],
          },
        },

        showlegend: true,
      }}
    />
  );
};
