import { apiSlice } from "../../api/apiSlice"

export const climateApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getClimate: builder.query({
            query: (id) => {
                if (id) return `report/JSLIC/climate/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetClimateQuery
} = climateApiSlice 