import { useId } from "react";

export const ButtonWithIcon = ({
  text,
  type = "botton",
  iconName,
  bgColor = "#2DABE3",
  color = "#fff",
  borderColor,
  iconPosition = "left",
  onClick = null,
  disabled = false,
}) => {
  const id = useId();

  return (
    <>
      <button
        id={id}
        type={type}
        disabled={disabled}
        onClick={onClick}
        className="simple-button"
        style={{
          border: borderColor ? "solid 2px " + borderColor : "none",
          background: bgColor,
        }}
      >
        {iconName && iconPosition === "left" && (
          <img
            style={{ marginRight: 10, verticalAlign: "middle" }}
            src={"/icons/" + iconName + ".svg"}
            alt=""
          />
        )}
        <span style={{ verticalAlign: "middle", color: color }}>{text}</span>
        {iconName && iconPosition === "right" && (
          <img
            style={{ marginLeft: 10, verticalAlign: "middle" }}
            src={"/icons/" + iconName + ".svg"}
            alt=""
          />
        )}
      </button>
    </>
  );
};
