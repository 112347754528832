import React from "react";

import Plotly from "./customPlotly";
import createPlotlyComponent from "react-plotly.js/factory";

const ViolinCompare = ({ data_before, data_after, margins }) => {
  const Plot = createPlotlyComponent(Plotly);
  const categories = ["Before intervention", "After Intervention"];
  const categoryToNumeric = {
    "Before intervention": 1,
    "After Intervention": 2,
  };
  const valuesA = data_before
    .filter((d) => d.costrutto === "wp_supervisor")
    .map((d) => d.value);
  const valuesB = data_after
    .filter((d) => d.costrutto === "wp_coworker")
    .map((d) => d.value);

  const lineA = data_before
    .filter((d) => d.costrutto === "wp_supervisor")
    .map((d) => ({ "id": d.id, "value": d.value }));
  const lineB = data_after
    .filter((d) => d.costrutto === "wp_coworker")
    .map((d) => ({ "id": d.id, "value": d.value }));

  const line_data = lineA.map((point) => {
    const A = point.value;

    const B = lineB.filter((d) => d.id === point.id);

    if (B) {
      return { "A": A, "B": B[0].value };
    }
    return null;
  });

  const avg_line = {
    A: lineA.map((p) => p.value).reduce((a, b) => a + b) / lineA.length,
    B: lineB.map((p) => p.value).reduce((a, b) => a + b) / lineA.length,
  };

  // Create traces for the violin plots
  let traceA = {
    type: "violin",
    y: valuesA,
    x: Array(valuesA.length).fill("Before intervention"),
    name: "Before intervention",
    side: "negative",
    points: false, // Don't display points here
    box: {
      visible: true,
    },
    meanline: {
      visible: true,
    },
  };

  let traceB = {
    type: "violin",
    y: valuesB,
    x: Array(valuesB.length).fill("After Intervention"),
    name: "After Intervention",
    side: "positive",
    points: false, // Don't display points here
    box: {
      visible: true,
    },
    meanline: {
      visible: true,
    },
  };

  // // Create traces for the connecting lines

  const lineTraces = line_data.map((point, i) => ({
    type: "scatter",
    mode: "lines",
    x: ["Before intervention", "After Intervention"].map(
      (category) => categoryToNumeric[category]
    ),
    y: [point.A, point.B],
    line: {
      color: "gray",
      //dash: "dash"
      width: 0.2,
      opacity: 0.1,
    },
    showlegend: false,
  }));

  const avgLineTraces = {
    type: "scatter",
    mode: "lines",
    name: "Average Impact",
    x: ["Before intervention", "After Intervention"].map(
      (category) => categoryToNumeric[category]
    ),
    y: [avg_line.A, avg_line.B],
    line: {
      color: "blue",
      //dash: "dash"
      width: 1.5,
      opacity: 0.1,
    },
    showlegend: true,
  };

  // Create scatter traces for the individual data points
  let scatterA = {
    type: "scatter",
    mode: "markers",
    x: Array(valuesA.length).fill("Before intervention"),
    y: valuesA,
    name: "Before intervention",
    marker: {
      color: "blue",
      size: 6,
    },
    showlegend: false,
  };

  let scatterB = {
    type: "scatter",
    mode: "markers",
    x: Array(valuesB.length).fill("After Intervention"),
    y: valuesB,
    name: "After Intervention",
    marker: {
      color: "orange",
      size: 6,
    },
    showlegend: false,
  };

  const move = (data, categoryToNumeric, offset) => {
    const Offsetdata = {
      ...data,
      x: data.x.map((category) => categoryToNumeric[category] + offset),
    };

    return Offsetdata;
  };

  scatterA = move(scatterA, categoryToNumeric, 0);
  scatterB = move(scatterB, categoryToNumeric, 0);

  // Convert violin categories to numeric for plotting
  traceA = move(traceA, categoryToNumeric, -0.05);
  traceB = move(traceB, categoryToNumeric, 0.05);

  const dataTraces = [
    traceA,
    traceB,
    ...lineTraces,
    avgLineTraces,
    scatterA,
    scatterB,
  ];

  return (
    <Plot
      data={dataTraces}
      style={{ width: "100%", height: "100%" }}
      useResizeHandler={true}
      layout={{
        autosize: true,
        font: {
          size: "14",
        },
        yaxis: {
          zeroline: false,
        },
        xaxis: {
          tickvals: [1, 2, 3],
          ticktext: categories,
          tickmode: "array",
          zeroline: false,
        },
        margin: {
          pad: 20,
          l: margins[0],
          r: margins[1],
          t: margins[2],
          b: margins[3],
        },
        violinmode: "overlay",
      }}
      config={{ editable: false }}
    />
  );
};

export default ViolinCompare;
