import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import { useDispatch } from "react-redux";
import { closeModal } from "./modalSlice";

function ModalComponent({
  isOpen,
  setIsOpen,
  size = "sm",
  title = "Modal heading",
  bodyText = "Modal Body Text.",
  functionButtonText = "Go to Store",
  modalFunction,
}) {
  const dispatch = useDispatch();

  const handleClose = () => {
    //for local use in components having a local isOpen state
    if (setIsOpen) {setIsOpen(false)}
    else{
      dispatch(closeModal());
    }
    
  };

  return (
    <>
      <Modal size={size} centered show={isOpen} onHide={handleClose}>
        <Modal.Header closeButton>
          <Modal.Title>{title}</Modal.Title>
        </Modal.Header>
        <Modal.Body>{bodyText}</Modal.Body>
        <Modal.Footer>
          <Button variant="secondary" onClick={handleClose}>
            Close
          </Button>
          {modalFunction && (
            <Button
              variant="primary"
              onClick={() => {
                modalFunction();
                handleClose();
              }}
            >
              {functionButtonText}
            </Button>
          )}
        </Modal.Footer>
      </Modal>
    </>
  );
}

export default ModalComponent;
