export default function ProfilePicture({thumbnail}) {
    
    if (thumbnail === true) {

        return <>
        
            <img src="/icons/profile.svg" style={{ height: '48px', width: 'auto'}} alt=""/>
        
    </>

    } else {
        return <>
        <div className="d-inline-block text-center h-100 position-relative">
            <img src="/icons/profile.svg" style={{height: '100%', width: 'auto'}} alt=""/>
          
        </div>
    </>


    }

}
