const impact_mock_data = [{
  "time_points": [
    "02/06/2017",
    "24/02/2019",
    "08/11/2020",
    "15/08/2022"
  ],
  "scale_descriptions": [
    "Initialized Scale",
    "Initialized Scale",
    "# Heading level 1",
    "La **WHO-5** (World Health Organization) è la principale misura unidimensionale del ***benessere psicologico*** che deriva da una serie di validazioni che hanno portato alla semplificazione ed al perfezionamento di una scala più ampia sviluppata per un progetto dell’Organizzazione Mondiale della Sanità (WHO, 1990).\r\n\r\n\r\nAlcuni esempi di item sono: “Mi sono sentito sveglio e di buonumore\", “Mi sono sentito calmo e rilassato” o “ La mia vita di tutti i giorni è piena di cose che mi interessano”."
  ],
  "scale_user_values": [
    {
      "label": "Rossi - A",
      "value": 4.78
    },
    {
      "label": "Rossi - A",
      "value": 4.07
    },
    {
      "label": "Rossi - A",
      "value": 4.11
    },
    {
      "label": "Rossi - A",
      "value": 3.33
    }
  ],
  "scale_data": [
    [
      {
        "value": 3.7777777,
        "id": 169,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.3333333,
        "id": 65,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 232,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 238,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 196,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2,
        "id": 230,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 37,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 315,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 268,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 210,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 295,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 171,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 327,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 314,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 80,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 104,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 99,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 236,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 136,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 113,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 59,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 274,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 250,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 63,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 226,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 121,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 19,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 76,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 308,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 316,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 286,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 62,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.3333333,
        "id": 47,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 118,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 95,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 157,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 296,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 50,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 32,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.7777777,
        "id": 180,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 317,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.2222223,
        "id": 14,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 301,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 2,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 110,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 325,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 161,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 91,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 326,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.6666667,
        "id": 17,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 81,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 16,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 309,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 219,
        "gender": "Femminile",
        "tenure": null,
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 105,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.1111112,
        "id": 289,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.4444444,
        "id": 34,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 170,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 183,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 324,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 21,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 209,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 57,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 310,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 177,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 42,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 184,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 245,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2,
        "id": 178,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 86,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 123,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 24,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 39,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 313,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 56,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 12,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.6666667,
        "id": 240,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 4,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 133,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 181,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.4444444,
        "id": 71,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.3333335,
        "id": 10,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 221,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 116,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.4444444,
        "id": 125,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1.4444444,
        "id": 160,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 106,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 191,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 3,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 98,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 143,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 153,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 131,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 89,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.6666667,
        "id": 199,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 114,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.3333333,
        "id": 203,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 256,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 222,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.2222223,
        "id": 20,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 262,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 90,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 73,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.2222223,
        "id": 220,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 280,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 213,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3,
        "id": 44,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.4444444,
        "id": 166,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 311,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 8,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 284,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 112,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 244,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 264,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.7777777,
        "id": 40,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.6666665,
        "id": 267,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.2222223,
        "id": 197,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 241,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 234,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.2222223,
        "id": 261,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 158,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 115,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 92,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 312,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.2222223,
        "id": 302,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.1111112,
        "id": 30,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.2222223,
        "id": 163,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 270,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 45,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 53,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.4444444,
        "id": 214,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 145,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 172,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.8888888,
        "id": 228,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 321,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.6666665,
        "id": 272,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.111111,
        "id": 294,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 305,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 82,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 260,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 237,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 66,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 51,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 258,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3,
        "id": 61,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 167,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 208,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.2222223,
        "id": 93,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.6666667,
        "id": 67,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 306,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.6666665,
        "id": 298,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 279,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 139,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 276,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 173,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.2222223,
        "id": 243,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 307,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 31,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.3333335,
        "id": 192,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.5555556,
        "id": 216,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 55,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 46,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 300,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 152,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 100,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 223,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.3333333,
        "id": 60,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 48,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.7777777,
        "id": 162,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 266,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 9,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.3333333,
        "id": 26,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 271,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.3333333,
        "id": 257,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.7777777,
        "id": 239,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 288,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 164,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.4444444,
        "id": 77,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 204,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 87,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 154,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 142,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.3333335,
        "id": 269,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 175,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.3333333,
        "id": 151,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 188,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 102,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 263,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 207,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 103,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.6666667,
        "id": 150,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3,
        "id": 109,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 275,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 107,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 185,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 290,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 138,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2,
        "id": 255,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 282,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.5555553,
        "id": 235,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 297,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1.8888888,
        "id": 322,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 11,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 69,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 190,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1.8888888,
        "id": 194,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.1111112,
        "id": 49,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 127,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1.8888888,
        "id": 84,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1.3333334,
        "id": 97,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.5555553,
        "id": 254,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1,
        "id": 176,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 72,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.2222223,
        "id": 249,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.7777777,
        "id": 174,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.888889,
        "id": 18,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 231,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 128,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 247,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.2222223,
        "id": 111,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 68,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5,
        "id": 7,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 6,
        "id": 233,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.5555556,
        "id": 202,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.4444444,
        "id": 78,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.1111112,
        "id": 291,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 58,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.6666665,
        "id": 205,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1.1111112,
        "id": 28,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.3333335,
        "id": 224,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 277,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 246,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.2222223,
        "id": 292,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 273,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.7777777,
        "id": 187,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 119,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 6,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 2.7777777,
        "id": 22,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.7777777,
        "id": 88,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 1,
        "id": 1,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.111111,
        "id": 198,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.8888888,
        "id": 148,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.888889,
        "id": 193,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.3333335,
        "id": 122,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.7777777,
        "id": 248,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 3.3333333,
        "id": 33,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4,
        "id": 126,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 4.4444447,
        "id": 5,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      },
      {
        "value": 5.4444447,
        "id": 212,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_supervisor"
      }
    ],
    [
      {
        "value": 2.3333333,
        "id": 20,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 272,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.2222223,
        "id": 103,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.814815,
        "id": 115,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.7777777,
        "id": 175,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7037036,
        "id": 112,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.925926,
        "id": 302,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 234,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.9629629,
        "id": 315,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.037037,
        "id": 154,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.0740743,
        "id": 277,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.185185,
        "id": 241,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.703704,
        "id": 269,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.8148148,
        "id": 174,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3703704,
        "id": 158,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.6296296,
        "id": 40,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5925927,
        "id": 88,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.851852,
        "id": 188,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.9629629,
        "id": 87,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3333335,
        "id": 264,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5,
        "id": 207,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4074075,
        "id": 166,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.074074,
        "id": 197,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.3703704,
        "id": 284,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.7037036,
        "id": 216,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 128,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.814815,
        "id": 204,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.037037,
        "id": 8,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 1,
        "id": 176,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.3333335,
        "id": 275,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3703704,
        "id": 244,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2222223,
        "id": 44,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.814815,
        "id": 142,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.0370371,
        "id": 261,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 288,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.888889,
        "id": 213,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.4814816,
        "id": 190,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.4444447,
        "id": 114,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.0740743,
        "id": 311,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 73,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.5185184,
        "id": 162,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3703704,
        "id": 78,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 239,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.6296296,
        "id": 90,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.888889,
        "id": 273,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3333333,
        "id": 254,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7037036,
        "id": 280,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5925927,
        "id": 222,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.7777777,
        "id": 77,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3,
        "id": 26,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.925926,
        "id": 205,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 256,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.037037,
        "id": 297,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 153,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.5925927,
        "id": 262,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3333333,
        "id": 199,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.925926,
        "id": 249,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.0740743,
        "id": 9,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.037037,
        "id": 127,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 89,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.5925927,
        "id": 193,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4444444,
        "id": 245,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7037036,
        "id": 203,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.703704,
        "id": 143,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.1111112,
        "id": 257,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5925925,
        "id": 220,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5555553,
        "id": 48,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2222223,
        "id": 98,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.814815,
        "id": 198,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.888889,
        "id": 181,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 131,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.111111,
        "id": 106,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5555553,
        "id": 223,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 148,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4444444,
        "id": 60,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 1.7777778,
        "id": 160,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3703704,
        "id": 49,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.925926,
        "id": 58,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 3,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3703704,
        "id": 221,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.888889,
        "id": 266,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.296296,
        "id": 119,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6296296,
        "id": 164,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.148148,
        "id": 10,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6296296,
        "id": 300,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.111111,
        "id": 12,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.2222223,
        "id": 125,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.0740743,
        "id": 133,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.4444444,
        "id": 97,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.703704,
        "id": 69,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.185185,
        "id": 231,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5,
        "id": 4,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.740741,
        "id": 46,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.5185184,
        "id": 224,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2592592,
        "id": 71,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.259259,
        "id": 56,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5185184,
        "id": 243,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.9629629,
        "id": 191,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.037037,
        "id": 100,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.037037,
        "id": 313,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 192,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.962963,
        "id": 310,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4814816,
        "id": 240,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2962964,
        "id": 123,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.037037,
        "id": 202,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3703704,
        "id": 11,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.6666667,
        "id": 169,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5555553,
        "id": 86,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.851852,
        "id": 31,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 267,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 39,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 184,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.259259,
        "id": 138,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5185184,
        "id": 145,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 55,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.111111,
        "id": 42,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.4074073,
        "id": 173,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6296296,
        "id": 324,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.8148148,
        "id": 178,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 57,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.0370371,
        "id": 194,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.7777777,
        "id": 271,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.2222223,
        "id": 235,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4814816,
        "id": 209,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6666665,
        "id": 276,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5185184,
        "id": 113,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.0740743,
        "id": 177,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4,
        "id": 183,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4074075,
        "id": 292,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 24,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 258,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.7777777,
        "id": 170,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7407408,
        "id": 307,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.888889,
        "id": 91,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.296296,
        "id": 21,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 105,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 298,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 33,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.851852,
        "id": 233,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.148148,
        "id": 219,
        "gender": "Femminile",
        "tenure": null,
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.4074073,
        "id": 282,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 306,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 34,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.2222223,
        "id": 81,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.4074075,
        "id": 187,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 1.4444444,
        "id": 1,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4,
        "id": 139,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2592592,
        "id": 17,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6666665,
        "id": 208,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.703704,
        "id": 2,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.5185184,
        "id": 309,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2592592,
        "id": 161,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.7777777,
        "id": 322,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.9259257,
        "id": 290,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5185184,
        "id": 291,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 325,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5925925,
        "id": 167,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3703704,
        "id": 152,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.4074073,
        "id": 326,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 301,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.6296296,
        "id": 84,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.9629629,
        "id": 289,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6296296,
        "id": 260,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.851852,
        "id": 14,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.6296296,
        "id": 67,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8148148,
        "id": 62,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.4444447,
        "id": 110,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 32,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.5555553,
        "id": 51,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.851852,
        "id": 111,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.3703704,
        "id": 185,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5925925,
        "id": 50,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 1.4444444,
        "id": 28,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 66,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6296296,
        "id": 317,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5555553,
        "id": 95,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4814816,
        "id": 151,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3703704,
        "id": 157,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.1851852,
        "id": 61,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.0740743,
        "id": 118,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.259259,
        "id": 82,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5925927,
        "id": 76,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.296296,
        "id": 296,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 286,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 1.925926,
        "id": 255,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.4074073,
        "id": 279,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.1111112,
        "id": 109,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8888888,
        "id": 316,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.851852,
        "id": 305,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.9629629,
        "id": 238,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.148148,
        "id": 47,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.740741,
        "id": 19,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.5185184,
        "id": 22,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.2592592,
        "id": 180,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.9629629,
        "id": 270,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4,
        "id": 121,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.4444447,
        "id": 237,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4,
        "id": 104,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.9259257,
        "id": 308,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.888889,
        "id": 250,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.296296,
        "id": 321,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.5555553,
        "id": 246,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.703704,
        "id": 247,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4444444,
        "id": 274,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5925925,
        "id": 150,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.4444444,
        "id": 228,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.4444447,
        "id": 226,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8148148,
        "id": 136,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.0740743,
        "id": 122,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 68,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.074074,
        "id": 294,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.814815,
        "id": 236,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 214,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.148148,
        "id": 171,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.3703704,
        "id": 59,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5.296296,
        "id": 80,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.0740743,
        "id": 6,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 107,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6666665,
        "id": 263,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.925926,
        "id": 314,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5,
        "id": 53,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 93,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5925925,
        "id": 99,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.037037,
        "id": 295,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5555553,
        "id": 72,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.148148,
        "id": 63,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.7777777,
        "id": 312,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 5,
        "id": 210,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.2222223,
        "id": 172,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6666665,
        "id": 212,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.703704,
        "id": 327,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.851852,
        "id": 37,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 163,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.7777777,
        "id": 7,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.814815,
        "id": 18,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.851852,
        "id": 230,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.5185184,
        "id": 102,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.0370371,
        "id": 16,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.8148148,
        "id": 268,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.4074073,
        "id": 232,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.7777777,
        "id": 30,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.5185184,
        "id": 126,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.259259,
        "id": 116,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 2.5555556,
        "id": 65,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3333335,
        "id": 45,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.6666665,
        "id": 248,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.148148,
        "id": 196,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 4.3333335,
        "id": 5,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_overall"
      },
      {
        "value": 3.4074075,
        "id": 92,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_overall"
      }
    ],
    [
      {
        "value": 4.4444447,
        "id": 5,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 169,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 126,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 122,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.6666665,
        "id": 33,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.5555553,
        "id": 193,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 1.5555556,
        "id": 1,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 292,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 148,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.6666665,
        "id": 88,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 190,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 119,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 198,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.4444444,
        "id": 22,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3,
        "id": 187,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.6666665,
        "id": 246,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.2222223,
        "id": 28,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 273,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 277,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 93,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 205,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.7777777,
        "id": 224,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 58,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 202,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 231,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 291,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.888889,
        "id": 233,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 6,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 111,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 78,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 7,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 247,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 18,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 72,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 128,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 174,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 1,
        "id": 176,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.6666667,
        "id": 249,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 254,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 127,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 297,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 97,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 49,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 207,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 69,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.4444444,
        "id": 84,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.1111112,
        "id": 194,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.4444444,
        "id": 11,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 235,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 138,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.8888888,
        "id": 322,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 282,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 152,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 290,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.5555556,
        "id": 255,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 185,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 109,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 151,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 107,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 150,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 263,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.3333335,
        "id": 275,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.111111,
        "id": 103,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 102,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.5555553,
        "id": 175,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 188,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 269,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 154,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 87,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 142,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 204,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.4444444,
        "id": 288,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.1111112,
        "id": 77,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 239,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.6666667,
        "id": 162,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.1111112,
        "id": 26,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.3333333,
        "id": 216,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 1.8888888,
        "id": 257,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 9,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 164,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 48,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 266,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 60,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 223,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 68,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 300,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 271,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 100,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.3333335,
        "id": 46,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 192,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 243,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 55,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.6666665,
        "id": 31,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.111111,
        "id": 173,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 307,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 276,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 298,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 258,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 139,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 306,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 145,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 208,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 279,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.1111112,
        "id": 67,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 167,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.4444447,
        "id": 51,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.6666665,
        "id": 260,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 61,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 66,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 82,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.6666665,
        "id": 237,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 305,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 321,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 270,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.6666667,
        "id": 294,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.8888888,
        "id": 228,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 214,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 272,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.7777777,
        "id": 172,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 53,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 163,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 312,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 45,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3,
        "id": 30,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 92,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 302,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 115,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 234,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 112,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 158,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 241,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 116,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.6666667,
        "id": 40,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 261,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.6666667,
        "id": 197,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 264,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 284,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 166,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.4444444,
        "id": 244,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 8,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 157,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 44,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 311,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 213,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 73,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.111111,
        "id": 114,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 280,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.5555553,
        "id": 90,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 267,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 222,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.3333333,
        "id": 220,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.4444447,
        "id": 262,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 256,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3,
        "id": 199,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 153,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 203,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3,
        "id": 89,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 143,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 131,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 98,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 106,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.111111,
        "id": 181,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 3,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.2222223,
        "id": 160,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3,
        "id": 245,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 221,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 191,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.4444444,
        "id": 125,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 10,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 133,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 12,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 71,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 4,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.5555556,
        "id": 20,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 56,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 240,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.6666665,
        "id": 313,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 123,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 310,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.3333333,
        "id": 39,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 86,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 184,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 24,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 178,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 42,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 57,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 324,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.2222223,
        "id": 177,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 209,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 183,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 21,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 170,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 105,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 91,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 34,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 219,
        "gender": "Femminile",
        "tenure": null,
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 113,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 81,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 289,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.5555553,
        "id": 309,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 17,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.2222223,
        "id": 161,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 2,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.5555553,
        "id": 326,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 325,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 301,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 110,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.7777777,
        "id": 14,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.4444444,
        "id": 32,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 62,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 317,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3,
        "id": 50,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.1111112,
        "id": 16,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 95,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.4444444,
        "id": 180,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 296,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 118,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 286,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 76,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 47,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 316,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 19,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 308,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 121,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 250,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 104,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 226,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 274,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.3333335,
        "id": 238,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 136,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.6666667,
        "id": 63,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.5555556,
        "id": 59,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 236,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5.3333335,
        "id": 80,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.6666665,
        "id": 171,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.8888888,
        "id": 99,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 314,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 295,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.4444447,
        "id": 327,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 5,
        "id": 210,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.888889,
        "id": 37,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.7777777,
        "id": 212,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 268,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.111111,
        "id": 230,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4.2222223,
        "id": 232,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 4,
        "id": 315,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.6666667,
        "id": 196,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_coworker"
      },
      {
        "value": 2.8888888,
        "id": 65,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_coworker"
      },
      {
        "value": 3.3333333,
        "id": 248,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_coworker"
      }
    ],
    [
      {
        "value": 4.3333335,
        "id": 102,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.2222223,
        "id": 20,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 157,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.8888888,
        "id": 93,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 145,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 116,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 245,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.1111112,
        "id": 113,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 238,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 190,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5,
        "id": 207,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.2222223,
        "id": 216,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.1111112,
        "id": 243,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 258,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 260,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 270,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 312,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 112,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 166,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.3333335,
        "id": 114,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.6666667,
        "id": 153,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 181,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 12,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.5555553,
        "id": 310,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 324,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 91,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 2,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 62,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 76,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 104,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.2222223,
        "id": 171,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 212,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.888889,
        "id": 248,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.2222222,
        "id": 292,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1,
        "id": 28,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 231,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 72,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 297,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.111111,
        "id": 138,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 151,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.6666667,
        "id": 154,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.1111112,
        "id": 162,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 223,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 300,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.888889,
        "id": 46,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 192,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.888889,
        "id": 31,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.4444447,
        "id": 173,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 276,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 298,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 306,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 208,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 167,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.4444447,
        "id": 51,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 66,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5,
        "id": 82,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.5555553,
        "id": 305,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 321,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.5555556,
        "id": 228,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 214,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 53,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 163,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.2222223,
        "id": 30,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 92,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 115,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 234,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 241,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 40,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 264,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.111111,
        "id": 284,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 8,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.8888888,
        "id": 44,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 213,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 73,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.4444447,
        "id": 90,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 222,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 256,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 199,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 89,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 143,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 98,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 106,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.6666666,
        "id": 160,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 221,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.5555553,
        "id": 10,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.6666667,
        "id": 133,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 4,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.888889,
        "id": 56,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 313,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 123,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 86,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 184,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 42,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 57,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 209,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 183,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 170,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 105,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.888889,
        "id": 219,
        "gender": "Femminile",
        "tenure": null,
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.111111,
        "id": 81,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 17,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 161,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 325,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.6666667,
        "id": 301,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.5555553,
        "id": 14,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 32,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 50,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 95,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 118,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 286,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 316,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 19,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 121,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 250,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 274,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 136,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 236,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.111111,
        "id": 80,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 314,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 295,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 5,
        "id": 210,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.888889,
        "id": 37,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 230,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.5555553,
        "id": 232,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 65,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 5,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 126,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.6666667,
        "id": 122,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.3333335,
        "id": 193,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.7777778,
        "id": 1,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 88,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 119,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.4444444,
        "id": 187,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.2222223,
        "id": 246,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 277,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.3333333,
        "id": 205,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 58,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 202,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.6666665,
        "id": 233,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.4444444,
        "id": 111,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 247,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 18,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 174,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1,
        "id": 176,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.1111112,
        "id": 254,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 127,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.2222223,
        "id": 49,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 69,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.7777777,
        "id": 11,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.111111,
        "id": 235,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 282,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 290,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.111111,
        "id": 185,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.1111112,
        "id": 109,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.1111112,
        "id": 150,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 263,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.7777777,
        "id": 175,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 269,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 87,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 204,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 288,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 239,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 26,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 9,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.8888888,
        "id": 48,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 60,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 266,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.1111112,
        "id": 257,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.7777777,
        "id": 77,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.5555553,
        "id": 142,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 188,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.888889,
        "id": 103,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 107,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.2222222,
        "id": 255,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 322,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.1111112,
        "id": 194,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.2222223,
        "id": 97,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.8888888,
        "id": 249,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 128,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.4444447,
        "id": 7,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 291,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.4444447,
        "id": 224,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 273,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.3333333,
        "id": 22,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 148,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 33,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 169,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 196,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "70 - SCV",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 268,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 327,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 99,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 59,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 226,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 308,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 47,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.3333335,
        "id": 296,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 317,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.4444447,
        "id": 110,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Dirigente",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 326,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.2222223,
        "id": 309,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 34,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 21,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 177,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.6666667,
        "id": 178,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 39,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3,
        "id": 240,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 71,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.7777778,
        "id": 125,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 3,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 131,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 203,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.7777777,
        "id": 262,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 280,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.7777777,
        "id": 311,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.8888888,
        "id": 244,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.3333334,
        "id": 197,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 158,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 302,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 45,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.1111112,
        "id": 172,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 294,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 237,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 61,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.111111,
        "id": 67,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 139,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 307,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 55,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "77 - DG",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.3333335,
        "id": 100,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.888889,
        "id": 164,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Responsabilità",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.2222223,
        "id": 275,
        "gender": "Femminile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 84,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.4444444,
        "id": 78,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.7777777,
        "id": 198,
        "gender": "Femminile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "71 - HRP",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.5555556,
        "id": 315,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.6666667,
        "id": 63,
        "gender": "Femminile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.5555556,
        "id": 180,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.5555556,
        "id": 289,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.6666665,
        "id": 24,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Operaio",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 1.6666666,
        "id": 191,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.2222223,
        "id": 220,
        "gender": "Maschile",
        "tenure": "4-5 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.3333333,
        "id": 261,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Piacenza",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 272,
        "gender": "Maschile",
        "tenure": "1-3 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 5.7777777,
        "id": 279,
        "gender": "Maschile",
        "tenure": null,
        "qualification": "Personale non subordinato",
        "responsibility": "Non ha incarichi",
        "department": "79 - SQS",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.3333335,
        "id": 271,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "78 - ASI",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.3333333,
        "id": 6,
        "gender": "Femminile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "11 - SFE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 2.1111112,
        "id": 16,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "15 - SSE",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 3.6666667,
        "id": 267,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "14 - TTD",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4,
        "id": 68,
        "gender": "Maschile",
        "tenure": "Più di 10 anni",
        "qualification": "Quadro",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      },
      {
        "value": 4.2222223,
        "id": 152,
        "gender": "Maschile",
        "tenure": "6-10 anni",
        "qualification": "Impiegato",
        "responsibility": "Non ha incarichi",
        "department": "12 - TGM",
        "location": "Milano",
        "costrutto": "wp_organization"
      }
    ]
  ],
  "scatter_titles": [
    {
      "title": "Trust in your team VS the organization",
      "xtitle": "Trust in the team",
      "ytitle": "Trust in the organization"
    }
  ],
  "scatter_scale_codes": [
    "wp_coworker",
    "wp_organization"
  ],
  "scatter_data": [
    {
      "wp_coworker": 2.5555556,
      "wp_organization": 2.2222223,
      "id": 20,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 3.8888888,
      "id": 157,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 2.8888888,
      "id": 93,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.2222223,
      "id": 145,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4,
      "id": 116,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 3,
      "wp_organization": 3,
      "id": 245,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 3.1111112,
      "id": 113,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 3.7777777,
      "id": 238,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 3.8888888,
      "id": 190,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 5,
      "id": 207,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.3333333,
      "wp_organization": 2.2222223,
      "id": 216,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 3.1111112,
      "id": 243,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 3.7777777,
      "id": 258,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.6666665,
      "wp_organization": 4.6666665,
      "id": 260,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4,
      "id": 270,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 3.7777777,
      "id": 312,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.2222223,
      "id": 112,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 3.2222223,
      "id": 166,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.111111,
      "wp_organization": 5.3333335,
      "id": 114,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Dirigente",
      "responsibility": "Responsabilità",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 3.6666667,
      "id": 153,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.111111,
      "wp_organization": 4.4444447,
      "id": 181,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.7777777,
      "id": 12,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.5555553,
      "id": 310,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4,
      "id": 324,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 4.6666665,
      "id": 91,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.3333335,
      "id": 2,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.5555556,
      "id": 62,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.4444447,
      "id": 76,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 3.8888888,
      "id": 104,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.6666665,
      "wp_organization": 5.2222223,
      "id": 171,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 3.7777777,
      "id": 212,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.3333333,
      "wp_organization": 4.888889,
      "id": 248,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 1.2222222,
      "id": 292,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.2222223,
      "wp_organization": 1,
      "id": 28,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 4.6666665,
      "id": 231,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.111111,
      "id": 72,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 4.7777777,
      "id": 297,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 5.111111,
      "id": 138,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 3.3333333,
      "id": 151,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 2.6666667,
      "id": 154,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.6666667,
      "wp_organization": 2.1111112,
      "id": 162,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4.4444447,
      "id": 223,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Dirigente",
      "responsibility": "Responsabilità",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4,
      "id": 300,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.3333335,
      "wp_organization": 5.888889,
      "id": 46,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 3.5555556,
      "id": 192,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.6666665,
      "wp_organization": 5.888889,
      "id": 31,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.111111,
      "wp_organization": 5.4444447,
      "id": 173,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Dirigente",
      "responsibility": "Responsabilità",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.3333335,
      "id": 276,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.6666665,
      "id": 298,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.3333335,
      "id": 306,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.111111,
      "id": 208,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3,
      "id": 167,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.4444447,
      "wp_organization": 5.4444447,
      "id": 51,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 3.3333333,
      "id": 66,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 5,
      "id": 82,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Dirigente",
      "responsibility": "Responsabilità",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.5555553,
      "id": 305,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.3333335,
      "id": 321,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.8888888,
      "wp_organization": 1.5555556,
      "id": 228,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 4.111111,
      "id": 214,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.7777777,
      "id": 53,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 3.7777777,
      "id": 163,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3,
      "wp_organization": 2.2222223,
      "id": 30,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 3,
      "id": 92,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4.111111,
      "id": 115,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.2222223,
      "id": 234,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 3.8888888,
      "id": 241,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.6666667,
      "wp_organization": 2.4444444,
      "id": 40,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 4.111111,
      "id": 264,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 5.111111,
      "id": 284,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 3.2222223,
      "id": 8,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 2.8888888,
      "id": 44,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.3333335,
      "id": 213,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4,
      "id": 73,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.5555553,
      "wp_organization": 5.4444447,
      "id": 90,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4.6666665,
      "id": 222,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.4444447,
      "id": 256,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3,
      "wp_organization": 3.3333333,
      "id": 199,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 3,
      "wp_organization": 3.7777777,
      "id": 89,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.3333335,
      "id": 143,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 2.4444444,
      "id": 98,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 3.7777777,
      "id": 106,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.2222223,
      "wp_organization": 1.6666666,
      "id": 160,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 3.2222223,
      "id": 221,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 5.5555553,
      "id": 10,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 3.6666667,
      "id": 133,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4.7777777,
      "id": 4,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 4.888889,
      "id": 56,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.6666665,
      "wp_organization": 4.6666665,
      "id": 313,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "77 - DG",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 2.5555556,
      "id": 123,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 4.2222223,
      "id": 86,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 3.7777777,
      "id": 184,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.111111,
      "id": 42,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.7777777,
      "id": 57,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 3,
      "id": 209,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.8888888,
      "id": 183,
      "gender": "Maschile",
      "tenure": null,
      "qualification": "Personale non subordinato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.3333335,
      "id": 170,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.2222223,
      "id": 105,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 4.888889,
      "id": 219,
      "gender": "Femminile",
      "tenure": null,
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 5.111111,
      "id": 81,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 3.3333333,
      "id": 17,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 3,
      "id": 161,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4,
      "id": 325,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 3.6666667,
      "id": 301,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 4.5555553,
      "id": 14,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 3.4444444,
      "wp_organization": 3.3333333,
      "id": 32,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3,
      "wp_organization": 3.8888888,
      "id": 50,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.4444447,
      "id": 95,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 4.6666665,
      "id": 118,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 4.3333335,
      "id": 286,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 2.4444444,
      "id": 316,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.4444447,
      "id": 19,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 4,
      "id": 121,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 4.6666665,
      "id": 250,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 3.2222223,
      "id": 274,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.7777777,
      "id": 136,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.4444447,
      "id": 236,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.3333335,
      "wp_organization": 5.111111,
      "id": 80,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 2.4444444,
      "id": 314,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 3.3333333,
      "id": 295,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 5,
      "id": 210,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.888889,
      "id": 37,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 2.4444444,
      "id": 230,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4.5555553,
      "id": 232,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.8888888,
      "wp_organization": 2.4444444,
      "id": 65,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4.111111,
      "id": 5,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 2.5555556,
      "id": 126,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 3.6666667,
      "id": 122,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.5555553,
      "wp_organization": 5.3333335,
      "id": 193,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 1.5555556,
      "wp_organization": 1.7777778,
      "id": 1,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.6666665,
      "wp_organization": 4.3333335,
      "id": 88,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 4,
      "id": 119,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3,
      "wp_organization": 1.4444444,
      "id": 187,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.6666665,
      "wp_organization": 5.2222223,
      "id": 246,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 4.7777777,
      "id": 277,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 2.3333333,
      "id": 205,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.8888888,
      "id": 58,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4.3333335,
      "id": 202,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.888889,
      "wp_organization": 5.6666665,
      "id": 233,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.4444444,
      "id": 111,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.3333335,
      "id": 247,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.6666665,
      "id": 18,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 2.4444444,
      "id": 174,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 1,
      "wp_organization": 1,
      "id": 176,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 1.1111112,
      "id": 254,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 3.8888888,
      "id": 127,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 2.2222223,
      "id": 49,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.6666665,
      "id": 69,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Operaio",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.4444444,
      "wp_organization": 2.7777777,
      "id": 11,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 5.111111,
      "id": 235,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 4.3333335,
      "id": 282,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4,
      "id": 290,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 5.111111,
      "id": 185,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 3.1111112,
      "id": 109,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.1111112,
      "id": 150,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.2222223,
      "id": 263,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4.3333335,
      "id": 102,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.5555553,
      "wp_organization": 5.7777777,
      "id": 175,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.4444447,
      "id": 269,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.8888888,
      "id": 87,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.2222223,
      "id": 204,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Dirigente",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.4444444,
      "wp_organization": 4.111111,
      "id": 288,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.8888888,
      "id": 239,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.1111112,
      "wp_organization": 2.5555556,
      "id": 26,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.7777777,
      "id": 9,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 3.8888888,
      "id": 48,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 3.2222223,
      "id": 60,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.7777777,
      "id": 266,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 1.8888888,
      "wp_organization": 2.1111112,
      "id": 257,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.1111112,
      "wp_organization": 2.7777777,
      "id": 77,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.5555553,
      "id": 142,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 4.4444447,
      "id": 188,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.111111,
      "wp_organization": 4.888889,
      "id": 103,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.7777777,
      "id": 107,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.5555556,
      "wp_organization": 1.2222222,
      "id": 255,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.8888888,
      "wp_organization": 3.5555556,
      "id": 322,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.1111112,
      "wp_organization": 2.1111112,
      "id": 194,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 2.2222223,
      "id": 97,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 2.6666667,
      "wp_organization": 2.8888888,
      "id": 249,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 3.7777777,
      "id": 128,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.4444447,
      "id": 7,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.5555556,
      "id": 291,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.7777777,
      "wp_organization": 5.4444447,
      "id": 224,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4.3333335,
      "id": 273,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.4444444,
      "wp_organization": 2.3333333,
      "id": 22,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.7777777,
      "id": 148,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.6666665,
      "wp_organization": 3.3333333,
      "id": 33,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 3.5555556,
      "id": 169,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 3.7777777,
      "id": 196,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "70 - SCV",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.5555556,
      "id": 268,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4.2222223,
      "id": 327,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 3.3333333,
      "id": 99,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 3,
      "id": 59,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.2222223,
      "id": 226,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 4.6666665,
      "id": 308,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 2.5555556,
      "id": 47,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 5.3333335,
      "id": 296,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.3333335,
      "id": 317,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 5.4444447,
      "id": 110,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Dirigente",
      "responsibility": "Responsabilità",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 3.7777777,
      "id": 326,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.5555553,
      "wp_organization": 5.2222223,
      "id": 309,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 3.7777777,
      "id": 34,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 3.7777777,
      "id": 21,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 4,
      "id": 177,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 2.6666667,
      "id": 178,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.3333333,
      "wp_organization": 2.5555556,
      "id": 39,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 3,
      "id": 240,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.888889,
      "wp_organization": 2.4444444,
      "id": 71,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.4444444,
      "wp_organization": 1.7777778,
      "id": 125,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.3333335,
      "id": 3,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 4,
      "id": 131,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 3.5555556,
      "id": 203,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.4444447,
      "wp_organization": 5.7777777,
      "id": 262,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 3.5555556,
      "id": 280,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 3.7777777,
      "id": 311,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.4444444,
      "wp_organization": 2.8888888,
      "id": 244,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.6666667,
      "wp_organization": 1.3333334,
      "id": 197,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4,
      "id": 158,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.5555556,
      "id": 302,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.111111,
      "id": 45,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.7777777,
      "wp_organization": 3.1111112,
      "id": 172,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.6666667,
      "wp_organization": 2.4444444,
      "id": 294,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.6666665,
      "wp_organization": 4.111111,
      "id": 237,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 3.3333333,
      "id": 61,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.1111112,
      "wp_organization": 4.111111,
      "id": 67,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.8888888,
      "wp_organization": 4.3333335,
      "id": 139,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.3333333,
      "id": 307,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 4.6666665,
      "id": 55,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "77 - DG",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.2222223,
      "wp_organization": 5.3333335,
      "id": 100,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 5,
      "wp_organization": 4.888889,
      "id": 164,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Responsabilità",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 5.3333335,
      "wp_organization": 5.2222223,
      "id": 275,
      "gender": "Femminile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.4444444,
      "wp_organization": 2.5555556,
      "id": 84,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.2222223,
      "wp_organization": 2.4444444,
      "id": 78,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.5555553,
      "wp_organization": 4.7777777,
      "id": 198,
      "gender": "Femminile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "71 - HRP",
      "location": "Milano"
    },
    {
      "wp_coworker": 4,
      "wp_organization": 3.5555556,
      "id": 315,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.6666667,
      "wp_organization": 2.6666667,
      "id": 63,
      "gender": "Femminile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 2.4444444,
      "wp_organization": 1.5555556,
      "id": 180,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.2222223,
      "wp_organization": 2.5555556,
      "id": 289,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 4.6666665,
      "id": 24,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Operaio",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.6666667,
      "wp_organization": 1.6666666,
      "id": 191,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.3333333,
      "wp_organization": 3.2222223,
      "id": 220,
      "gender": "Maschile",
      "tenure": "4-5 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.5555556,
      "wp_organization": 2.3333333,
      "id": 261,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Piacenza"
    },
    {
      "wp_coworker": 4.7777777,
      "wp_organization": 3.3333333,
      "id": 272,
      "gender": "Maschile",
      "tenure": "1-3 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 5.7777777,
      "id": 279,
      "gender": "Maschile",
      "tenure": null,
      "qualification": "Personale non subordinato",
      "responsibility": "Non ha incarichi",
      "department": "79 - SQS",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.3333335,
      "wp_organization": 4.3333335,
      "id": 271,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "78 - ASI",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.111111,
      "wp_organization": 3.3333333,
      "id": 6,
      "gender": "Femminile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "11 - SFE",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.1111112,
      "wp_organization": 2.1111112,
      "id": 16,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "15 - SSE",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 3.6666667,
      "id": 267,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "14 - TTD",
      "location": "Milano"
    },
    {
      "wp_coworker": 4.4444447,
      "wp_organization": 4,
      "id": 68,
      "gender": "Maschile",
      "tenure": "Più di 10 anni",
      "qualification": "Quadro",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    },
    {
      "wp_coworker": 3.7777777,
      "wp_organization": 4.2222223,
      "id": 152,
      "gender": "Maschile",
      "tenure": "6-10 anni",
      "qualification": "Impiegato",
      "responsibility": "Non ha incarichi",
      "department": "12 - TGM",
      "location": "Milano"
    }
  ]
}]

export default impact_mock_data;
