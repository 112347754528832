import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject
} from "../../features/projects/projectSlice";
import { useGetWorkNeedsQuery } from "../../features/report/workNeedsApiSlice";
import Loader from "../Loader";
import BarChartStackedLayout from "../graph/BarChartStackedLayout";
import ScatterMultiLayout from "../graph/ScatterMultiLayout";
import FilterBar from "../../layout/FilterBar";
import { splitArray } from "../functions/functions";

const WorkNeeds = () => {
  const selectedProject = useSelector(SelectCurrentProject)
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);

  const {
    data: scatterData,
    isLoading,
    isSuccess,
    isError,
    error,
  } = useGetWorkNeedsQuery(selectedProject);

  var bisogni = [[], [], [], [], []];
  {
    /*
   //to prepare the bargraph data

    function getBarData() {
    //grafico_a_barre_bisogni_lavorativi.sort((a,b)=>b.punteggio_pct-a.punteggio_pct)

    workneedsData.grafico_a_barre_bisogni_lavorativi.map((row) => {
      if (row.costrutto === "Growth") {
        bisogni[0].push({
          y: row["scelto"],
          x: row["punteggio"],
          label: row["punteggio"].toString(),
          name: "Growth",
        });
      }
      if (row.costrutto === "Social") {
        bisogni[1].push({
          y: row["scelto"],
          x: row["punteggio"],
          label: row["punteggio"].toString(),
          name: "Social",
        });
      }
      if (row.costrutto === "Convenience") {
        bisogni[2].push({
          y: row["scelto"],
          x: row["punteggio"],
          label: row["punteggio"].toString(),
          name: "Convenience",
        });
      }
      if (row.costrutto === "Extrinsic") {
        bisogni[3].push({
          y: row["scelto"],
          x: row["punteggio"],
          label: row["punteggio"].toString(),
          name: "Extrinsic",
        });
      }
      if (row.costrutto === "Intrinsic") {
        bisogni[4].push({
          y: row["scelto"],
          x: row["punteggio"],
          label: row["punteggio"].toString(),
          name: "Intrinsic",
        });
      }
    });
  }

  // old scales to be loaded:

      workneedsData.job_satisfaction.forEach((row) => {
      data0[0].push({ target: row[filter], score: row["score"] });
    });
    workneedsData.social_effort_and_reward_balance.forEach((row) => {
      data0[1].push({ target: row[filter], score: row["score"] });
    });
  
  */
  }

  function extract_data(data) {
    if (!data) return []
    return data.map(item => ({
      target: item["filter_value"],
      x: item.performance_importance * 100,
      y: item.performance_satisfaction * 100,
      label: item.scelto
    }));
  }

  function filterData(data, filter) {
    let filter_value = filter === "costrutto" ? "overall" : filter
    let newData = splitArray(
      extract_data(data[filter_value]), "Satisfaction versus Importance"
    );
    return newData;
  }

  let inputData = [[],[]]
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    inputData = filterData(scatterData.items, filterArray[selectedFilters[0]]);
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <FilterBar selectedFilters={selectedFilters} />

      <div className="container">
        <div className="row">
          <ScatterMultiLayout
            dataset={inputData[0]}
            titles={inputData[1]}
            range = {[[-100,100],[-100,100]]}
            ytitle={"Satisfaction (performance)"}
            xtitle={"Importance (performance)"}
            labeled={true}
            showlegend={false}
            full_width={false}
          />
        </div>
      </div>
    </>
  );
};

export default WorkNeeds;
