import React, { useEffect, useState, useRef } from "react";
import { Network } from "vis-network";
import { unpack } from "../functions/functions";

const NetworkGraph = ({ Nodes, Edges }) => {
  // Create a ref to provide DOM access
  const visJsRef = useRef(null);
  const [selectValue, setSelectValue] = useState([
    "Select By Id",
    "Select By Group",
  ]);
  const [data, setData] = useState([Nodes, Edges]);

  let groups = Nodes.map((value) => value.group)
    .filter((value, index, _arr) => _arr.indexOf(value) === index)
    .sort();
  let ids = Nodes.filter((value) => value.label !== undefined).map((value) => ({
    id: value.id,
    label: value.label,
  }));

  let options = {
    nodes: {
      shape: "dot",
      fixed: {
        x: false,
        y: false,
      },
      size: 10,
    },
    edges: {
      arrows: {
        to: {
          enabled: true,
          scaleFactor: 0.5,
          type: "arrow",
        },
      },
      width: 1.5,
      color: {
        opacity: 0.5,
      },
    },
    physics: false,
  };

  useEffect(() => {
    setSelectValue(["Select By Id", "Select By Group"]);
  }, [Nodes]);

  const handleGroupChange = (event) => {
    event.preventDefault();
    let newState = ["Select By Id", event.target.value];
    setSelectValue(newState);
  };

  const handleIdChange = (event) => {
    event.preventDefault();
    let newState = [parseInt(event.target.value), "Select By Group"];
    setSelectValue(newState);
  };

  const handleSelectNode = (node) => {
    let newState = [parseInt(node), "Select By Group"];
    setSelectValue(newState);
  };

  function updateNodes(filterMode, filter) {
    let newDataState = [[], []];
    let filteredEdges = [];
    let related_ids = [];
    let idList = [];
    switch (filterMode) {
      case "id":
        //get list of related nodes ids
        related_ids = unpack(
          Edges.filter((e) => e.from === filter || e.to === filter),
          "from"
        );
        related_ids.push(
          ...unpack(
            Edges.filter((e) => e.from === filter || e.to === filter),
            "to"
          )
        );

        idList = [...new Set(related_ids)];
        newDataState[0] = Nodes.map((node) => {
          if (node.id === filter) {
            return { ...node, borderWidth: 3, opacity: 1.0 };
          } else if (idList.includes(node.id)) {
            return { ...node, opacity: 0.8 };
          } else {
            return {
              ...node,
              label: null,
              color: "#C8C8C8",
              size: 5,
              opacity: 0.2,
            };
          }
        });
        filteredEdges = unpack(
          Nodes.filter((node) => idList.includes(node.id)),
          "id"
        );
        newDataState[1] = Edges.map((edge) =>
          filteredEdges.includes(edge.from)
            ? { ...edge, color: { opacity: 1 } }
            : { ...edge, color: { opacity: 0.2 } }
        );
        break;
      case "group":
        newDataState[0] = Nodes.map((node) =>
          node.group === filter
            ? { ...node }
            : { ...node, label: null, size: 5, color: "#C8C8C8", opacity: 0.2 }
        );
        filteredEdges = unpack(
          Nodes.filter((node) => node.group === filter),
          "id"
        );
        newDataState[1] = Edges.map((edge) =>
          filteredEdges.includes(edge.from)
            ? { ...edge, color: { opacity: 1 } }
            : { ...edge, color: { opacity: 0.2 } }
        );
        break;
      default:
        newDataState[0] = Nodes.map((node) => ({ ...node }));
        newDataState[1] = Edges.map((edge) => ({ ...edge }));
    }
    setData(newDataState);
  }

  useEffect(() => {
    const [selectById, selectByGroup] = selectValue;

    if (selectById === "Select By Id" && selectByGroup === "Select By Group") {
      updateNodes("None");
    } else if (selectById !== "Select By Id") {
      updateNodes("id", selectById);
    } else {
      updateNodes("group", selectByGroup);
    }
  }, [selectValue]);

  useEffect(() => {
    const network =
      visJsRef.current &&
      new Network(visJsRef.current, {
        nodes: data[0],
        edges: data[1],
        options,
      });

    // Use `network` here to configure events, etc
    network.on("selectNode", function (params) {
      handleSelectNode(params.nodes[0]);
    });
  }, [visJsRef, data]);

  return (
    <>
      <div className="container">
        <div className="col-lg-6 mt-3">
          <div classnName="row">
            <select
              style={{ width: 200 }}
              value={selectValue[0]}
              defaultValue={"Select By Id"}
              onChange={(event) => handleIdChange(event)}
              className="form-control"
              id="opt1"
            >
              <option value={"Select By Id"}>{"Select By Id"}</option>
              {ids.map((option) => (
                <option value={option.id}>{option.label}</option>
              ))}
            </select>
            <br />
            <select
              style={{ width: 200 }}
              value={selectValue[1]}
              defaultValue={"Select By Id"}
              onChange={(event) => handleGroupChange(event)}
              className="form-control"
              id="opt1"
            >
              <option value={"Select By Group"}>{"Select By Group"}</option>
              {groups.map((option) => (
                <option value={option}>{option}</option>
              ))}
            </select>
          </div>

          <div ref={visJsRef} style={{ height: 1200, width: 1200 }} />
        </div>
      </div>
    </>
  );
};

export default NetworkGraph;
