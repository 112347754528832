import { useSelector } from "react-redux";
import Stars from "./graph/Stars";
import PieChartLayout from "./graph/PieChartLayout";
import { PieColors } from "../utils/PieColors";
import {
  selectAllStarGroups,
  selectAllModules,
} from "../features/auth/authSlice";

const FilterLayout = ({ starData, pieData, hasModule, OverlayComponent }) => {
  const starGroups = useSelector(selectAllStarGroups);
  const modules = useSelector(selectAllModules);

  function getStarValue(data, id) {
    if (!starData) return 0
    let value = data.filter((e) => e.star_group === id).map((obj) => obj.value);
    if (value[0]) return value[0];
    else return 0;
  }
  function getPieData(filterValue) {
    let filteredPieData = pieData
      .filter((e) => e.filterValue === filterValue)
      .map((obj) => obj);
    if (filteredPieData[0]) return filteredPieData[0];
    else return 0;
  }

  const colors = [
    "lightBlue",
    "green",
    "orange",
    "blue",
    "red",
    "copper",
    "goldgreen",
    "coral",
    "kelly",
    "chocolate",
    "scarlet",
    "mustar",
    "olive",
  ];


  return (
    <>
      <div className="row">
        {starData[0].map((e, i) => {
          return (
            <div key={"filter_" + i} className="col-xl-4 col-lg-6 my-4">
              <div className="box h-lg-100">
                <div className={"text-center box-title-big"}>
                  {starData[1][i]}
                </div>
                <div className="d-flex flex-column align-items-center py-4">
                  {modules[1].map((module, moduleIndex) => {
                    let starGroupsItems = starGroups.filter(
                      (e) => e.module === module.code
                    );
                    if (starData.length === 0) return null;
                    return (
                      <>
                        {starGroupsItems.map((element, index) => {
                          return (
                            <div className="mb-3">
                              <Stars
                                points={getStarValue(e, element.id)}
                                enabled={hasModule(element.module)}
                                color={colors[i % colors.length]}
                                layout={2}
                                title={element.name}
                              />
                            </div>
                          );
                        })}
                      </>
                    );
                  })}
                </div>

                <div className="mt-4">
                  <PieChartLayout
                    color={PieColors.lightBlue}
                    chart_id={1}
                    pieData={getPieData(starData[1][i])}
                    perc={true}
                    title={true}
                  />
                </div>

                <OverlayComponent />
              </div>
            </div>
          );
        })}
      </div>
    </>
  );
};

export default FilterLayout;
