import { apiSlice } from "../../api/apiSlice"

export const workNeedsApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getWorkNeeds: builder.query({
            query: (id) => {
                if (id) return `report/JSLIC/work-needs/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetWorkNeedsQuery
} = workNeedsApiSlice 