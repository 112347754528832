import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Popover from 'react-bootstrap/Popover';
export default function OverlayDiv({comp, header, body}) {
  return (
    
    <div className="overlayCustom" >
    <OverlayTrigger
       trigger="click"
       key={'placement'}
       placement={'top'}
       overlay={
         <Popover id={`popover-positioned-top`}>
           <Popover.Header as="h3">{header}</Popover.Header>
           <Popover.Body>
           {body}
           </Popover.Body>
         </Popover>
       }
      >
    {comp}
    </OverlayTrigger>
    </div>
  )
}
