import { apiSlice } from "../../api/apiSlice"
import { createSlice } from "@reduxjs/toolkit";

const initialState = {
    selectedProject: null,
    projects: [],
    selectedFilters: ['Overall'],
    filters: [],
    projectData: {}
}

export const projectSlice = createSlice({
    name: 'projects',
    initialState,
    reducers: {
        setProjects: (state, action) => {
            const { data } =
              action.payload;
            
              state.projects = data.map((item) => {
                return {
                  id: item.id,
                  name: item.name
                };
              });
            
            if (state.projects.length > 0) {
                state.selectedProject = state.projects.toReversed()[0].id
                state.selectedFilters = ['Overall']
                //below reformatting added for compatibility with the current frontend
                // TODO: refactor the filters part of the frontend to directly use arrays instead of objects.
                let filterData = {"Overall":"costrutto"}
                data.find(({ id }) => id === state.selectedProject).filters
                             .forEach(item=> { filterData[item.name]= item.column_name })
                state.filters = filterData
            } else {
                state.selectedProject = null
                state.selectedFilters = ['Overall']
                state.filters = []
            } 
            state.projectData = data;
          },
        updateProject: {
            reducer(state, action) {
                console.log(action.payload)
                state.selectedProject= action.payload
                state.selectedFilters= ['Overall']
                let filterData = {"Overall":"costrutto"}
                state.projectData.find(({ id }) => id === state.selectedProject).filters
                            .forEach(item=> { filterData[item.name]= item.column_name })
                state.filters = filterData
            },
           
        },
        updateFilters: {
            reducer(state, action) {
                state.selectedFilters= action.payload
            },
            prepare(filter) {
                return {
                    payload: [filter]
                }
            }
        },
    },
    extraReducers: (builder) => {
        builder.addMatcher(
          apiSlice.endpoints.getProjects.matchFulfilled,
          (state, { payload }) => {
            const { data } = payload;
          
            state.projects = data.map((item) => {
                return {
                  id: item.id,
                  name: item.name
                };
              });
          
          if (state.projects.length > 0) {

              state.selectedProject = state.projects.toReversed()[0].id
              state.selectedFilters = ['Overall']              
              //below reformatting added for compatibility with the current frontend
              // TODO: refactor the filters part of the frontend to directly use arrays instead of objects.
              let filterData = {"Overall":"costrutto"}
              data.find(({ id }) => id === state.selectedProject).filters
                           .forEach(item=> { filterData[item.name]= item.column_name })
              state.filters = filterData
          } else {
              state.selectedProject = null
              state.selectedFilters = ['Overall']
              state.filters = []
          } 
          state.projectData = data;
          }
        )
      },
})


export const { setProjects, updateProject, updateFilters } = projectSlice.actions

export const SelectCurrentProject = (state) => state.projects.selectedProject
export const SelectCurrentFilters = (state) => state.projects.selectedFilters
export const selectAllProjects = (state) => state.projects.projects
export const SelectAllFilters = (state) => state.projects.filters

export default projectSlice.reducer