
import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject
} from "../../features/projects/projectSlice";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useGetBalanceQuery } from "../../features/report/balanceApiSlice";
import Loader from "../Loader";
import ViolinLayout from "../graph/ViolinLayout";
import FilterBar from "../../layout/FilterBar";
import { filterData } from "../functions/functions";

const Inail = () => {
  const selectedProject = useSelector(SelectCurrentProject)
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const activeRole = useSelector(selectActiveRole);

  let showDataPoints = false;
  activeRole === "user_admin"
    ? (showDataPoints = true)
    : (showDataPoints = false);

  const { data, isLoading, isSuccess, isError, error } = useGetBalanceQuery(selectedProject);

  const handleFilterCheck = () => {
    return (
      activeRole === "user_admin" &&
      selectedFilters.length > 0 &&
      selectedFilters[0] !== "Overall"
    );
  };

  let scaleData = null
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    scaleData= filterData(data, filterArray[selectedFilters[0]]);
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

 // TODO! Validator on violin marker data if exist in dataset!
  return (
    <>
    <FilterBar selectedFilters={selectedFilters} />

    <div className="container">
      <div className="row">
        {scaleData && scaleData.map((item, i) => {
          return (
            <ViolinLayout
              multiple={handleFilterCheck()}
              markerData = {data.scale_user_values[i]}
              marker = {!showDataPoints && !handleFilterCheck()}
              showDataPoints={showDataPoints && !handleFilterCheck()}
              title={data.scale_titles[i]}
              info_button = {true}
              scale_info= {data.scale_descriptions[i]}
              dataset={item}
            />
          );
        })}
      </div>
    </div>
  </>
  );
};

export default Inail;
