import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject
} from "../../features/projects/projectSlice";
import { useGetParticipationQuery } from "../../features/report/participationApiSlice";
import Loader from "../Loader";
import BarChartStackedLayout from "../graph/BarChartStackedLayout";

const Participation = () => {
  const selectedProject = useSelector(SelectCurrentProject)
  const { data, isLoading, isSuccess, isError, error } =
    useGetParticipationQuery(selectedProject);

  var dataset = [[], []];
  function getParticipationData() {
    data.tasso_di_partecipazione.forEach((row) => {
      dataset[0].push({
        y: "Parte " + row["parte"].toString(),
        x: row["complete"],
        label: row["label"],
        name: "complete",
      });
      dataset[1].push({
        y: "Parte " + row["parte"].toString(),
        x: row["totali"] - row["complete"],
        label: `${row["totali"] - row["complete"]}`,
        name: "incomplete",
      });
    });
  }

  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    getParticipationData();
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <div className="container">
        <div className="row">
          <BarChartStackedLayout
            dataset={dataset}
            title={"Participation rate"}
            ytitle={""}
            xtitle={"Participanti"}
            objectArray={[0]}
            full_width={false}
          />
        </div>
      </div>
    </>
  );
};

export default Participation;
