import { useSelector } from "react-redux";
import {
  useGetProjectEmployeesQuery,
  useUpdateUserAccessMutation,
} from "../features/projects/projectApiSlice";
import { SelectCurrentProject } from "../features/projects/projectSlice";
import { EmployeeTable } from "../components/data-table/EmployeeTable";
import withReactContent from "sweetalert2-react-content";
import { CircleFill } from "react-bootstrap-icons";
import Swal from "sweetalert2";

const MySwal = withReactContent(
    Swal.mixin({
      customClass: {
        confirmButton: "btn btn-primary m-2",
        cancelButton: "btn btn-secondary m-2",
      },
      buttonsStyling: false,
    })
  );

const ProjectEmployees = () => {
  const pid = useSelector(SelectCurrentProject);
  const [UpdateUserAccess] = useUpdateUserAccessMutation();

  const handleChangeActive = async (e, row) => {
    e.preventDefault();
    const user = `${row.original.name} - ${row.original.surname}`;
    const action = row.original.active ? "Disable" : "Enable";
    const result = await fireConfirm(user, action);
    if (result) {
      const data = {
        Active: !row.original.active,
        email: row.original.email,
      };
      await UpdateUserAccess({ pid, body: data })
        .unwrap()
        .then((payload) => {
          fireModal(payload);
        })
        .catch((error) => {
          fireModal(error, (error = true));
        });
    }
  };

  const fireConfirm = (user, action) => {
    return new Promise((resolve, reject) => {
      MySwal.fire({
        title: <h3>{`${action} user access`}</h3>,
        html: (
          <i>
            {` Do you want to ${action} user access to the trustie platform for the following user ?  `}
            <br /> <br />
            {user}
          </i>
        ),
        showCancelButton: true,
        cancelButtonText: "Cancel",
        confirmButtonText: "Confirm",
      }).then((result) => {
        if (result.isConfirmed) {
          resolve(true);
        } else {
          resolve(false);
        }
      });
    });
  };

  const fireModal = (input, error = false) => {
    if (!error) {
      MySwal.fire({
        title: <h3>{input.message.title}</h3>,
        showCancelButton: false,
        confirmButtonText: "Close",
        icon: "success",
      });
    } else
      MySwal.fire({
        title: <h3>{input.data.message}</h3>,
        html: <i>{input.data.data}</i>,
        confirmButtonText: "close",
        icon: "error",
      });
  };

  const COLUMNS = [
    {
      Header: "Active",
      accessor: "active",
      editable: false,
      Cell: ({ row, value }) => {
        if (row.original.email) {
          return (
            <>
              {value ? (
                <CircleFill
                  role="button"
                  onClick={(e) => {
                    handleChangeActive(e, row);
                  }}
                  color={"#81E32D"}
                  size={18}
                />
              ) : (
                <CircleFill
                  role="button"
                  onClick={(e) => {
                    handleChangeActive(e, row);
                  }}
                  color={"#D12621"}
                  size={18}
                />
              )}
            </>
          );
        } else {
          return <></>;
        }
      },
      sortType: (rowA, rowB, columnId) => {
        const a = rowA.values[columnId] ? 1 : 0;
        const b = rowB.values[columnId] ? 1 : 0;
        return a - b;
      },
    },
    {
      Header: "Name",
      accessor: "name",
      editable: false,
      //disableFilters: true,
      //Cell: ({ value }) => Number(value),
    },
    {
      Header: "Surname",
      accessor: "surname",
      editable: false,
    },
    {
      Header: "Participant",
      id: "participant",
      accessor: "email",
      editable: false,
      //disableFilters: true,
      Cell: ({ value }) => {
        return value ? "true" : "false";
      },
      sortType: (rowA, rowB, columnId) => {
        const a = rowA.values[columnId] ? 1 : 0;
        const b = rowB.values[columnId] ? 1 : 0;
        return a - b;
      },
    },
    {
      Header: "Email",
      accessor: "email",
      id: "email",
      editable: false,
    },

    {
      Header: "Department",
      accessor: "department",
      editable: false,
    },
    {
      Header: "Location",
      accessor: "location",
      editable: false,
    },
  ];

  //basic API call loading the data
  const {
    isLoading,
    isError,
    data: employees,
  } = useGetProjectEmployeesQuery(pid);

  return (
    <>
      {employees && (
        <EmployeeTable
          columns={COLUMNS}
          data={employees["items"]}
          full_width={true}
          error={isError}
          loading={isLoading}
        />
      )}
    </>
  );
};

export default ProjectEmployees;
