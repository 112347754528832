import React, { useMemo } from "react";
import { Table } from "react-bootstrap";
import { ArrowDown, ArrowUp } from "react-bootstrap-icons";
import {
  useTable,
  useSortBy,
  useGlobalFilter,
  useFilters,
  usePagination,
  useRowSelect,
} from "react-table";
import { ColumnFilter } from "./ColumnFilter";
import TableToolbar from "./TableToolbar";

export const EmployeeTable = ({ columns, data, full_width = false }) => {
  const defaultColumn = useMemo(() => {
    return {
      Filter: ColumnFilter,
    };
  }, []);

  let col_class = full_width ? "col-lg-12 mt-4 px-4" : "col-lg-6 mt-4 pb-3";

  const {
    getTableProps,
    getTableBodyProps,
    headerGroups,
    page,
    nextPage,
    canNextPage,
    previousPage,
    canPreviousPage,
    pageOptions,
    gotoPage,
    pageCount,
    setPageSize,
    prepareRow,
    state,
    setGlobalFilter,
  } = useTable(
    {
      columns,
      data,
      defaultColumn,
      autoResetPage: true,
    },
    useFilters,
    useGlobalFilter,
    useSortBy,
    usePagination,
    useRowSelect
  );

  const { globalFilter, pageIndex, pageSize } = state;

  return (
    <>
      <div className={col_class}>
        <div className="box">
          <div className="box-title"> List of Employees </div>

          <div className="overflow-hidden">
            <TableToolbar
              setGlobalFilter={setGlobalFilter}
              globalFilter={globalFilter}
            />
            <div className="dataTables_wrapper">
              <div style={{ maxWidth: "100%", overflowX: "auto" }}>
                <Table
                  {...getTableProps()}
                  className="table dataTable display table-employees "
                >
                  <thead>
                    {headerGroups.map((headerGroup) => (
                      <tr {...headerGroup.getHeaderGroupProps()}>
                        {headerGroup.headers.map((column) => (
                          <th
                            {...column.getHeaderProps(
                              column.getSortByToggleProps()
                            )}
                          >
                            {column.render("Header")}
                            <span className="ml-1">
                              {column.isSorted ? (
                                column.isSortedDesc ? (
                                  <ArrowDown />
                                ) : (
                                  <ArrowUp />
                                )
                              ) : (
                                ""
                              )}
                            </span>
                          </th>
                        ))}
                      </tr>
                    ))}
                  </thead>

                  <tbody {...getTableBodyProps()}>
                    {page.map((row) => {
                      prepareRow(row);
                      {
                        return (
                          <tr {...row.getRowProps()}>
                            {row.cells.map((cell) => {
                              return (
                                <td {...cell.getCellProps()}>
                                  {" "}
                                  {cell.render("Cell")}
                                </td>
                              );
                            })}
                          </tr>
                        );
                      }
                    })}
                  </tbody>
                </Table>
              </div>
              <div className="d-flex justify-content-between  table-footer">
                <div className="d-flex d-inline-block align-items-center">
                  <span style={{ marginRight: "30px" }}>
                    Page{" "}
                    <strong>
                      {pageIndex + 1}/{pageOptions.length}
                    </strong>
                    {""}
                  </span>
                  <span>
                    <select
                      className="table-footer"
                      value={pageSize}
                      onChange={(e) => setPageSize(Number(e.target.value))}
                    >
                      {[10, 25, 50].map((pageSize) => (
                        <option key={pageSize} value={pageSize}>
                          Show {pageSize}
                        </option>
                      ))}
                    </select>
                  </span>
                </div>

                <span>
                  <div className="text-center">
                    <button
                      className="btn btn-sm btn-outline-dark"
                      style={{
                        borderRadius: "50px",
                        minWidth: "120px",
                        marginRight: "10px",
                      }}
                      onClick={() => previousPage()}
                      disabled={!canPreviousPage}
                    >
                      Previous
                    </button>
                    <button
                      className="btn btn-sm btn-outline-dark"
                      style={{ borderRadius: "50px", minWidth: "120px" }}
                      onClick={() => nextPage()}
                      disabled={!canNextPage}
                    >
                      Next
                    </button>
                  </div>
                </span>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
