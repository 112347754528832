import React from "react";
import { paths } from "../../config/routes";
import { useNavigate, useLocation } from "react-router-dom";
import { useMemo } from "react";

export const MenuFooter = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const handleOnClick = (path) => {
    navigate(path);
  };

  const inSettings = useMemo(() => {
    return location.pathname.includes(paths.settings);
  }, [location.pathname]);

  const inWizard = useMemo(() => {
    return location.pathname.includes(paths.project_wizard);
  }, [location.pathname]);

  return (
    <div
      style={{
        display: "flex",
        flexDirection: "column",
        justifyContent: "center",
        marginTop: "auto",
      }}
    >
      {!inSettings  && (
          <div className="sidebarFooter">
            <div
              role="button"
              onClick={() => {
                handleOnClick("/settings/profile");
              }}
            >
              Account
            </div>
            <div
              role="button"
              onClick={() => {
                handleOnClick("/project-employees");
              }}
            >
              {" "}
              Project Settings
            </div>
          </div>
        )}
        {inSettings  && (
          <div className="sidebarFooter">
            <div
              role="button"
              onClick={() => {
                handleOnClick("/");
              }}
            >
              Home
            </div>
          </div>
        )}
    </div>
  );
};
