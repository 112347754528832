import {ScatterPlot} from "./ScatterPlot";

const ScatterMultiLayout = ({
  titles       = ['Title of the object'],
  dataset,
  xtitle      = 'Title x-axis',
  ytitle      = 'Title y-axis',
  labeled,
  range,
  dashLines = false,
  marker=false,
  margins= [100,100,10,100],
  full_width = false,
  showlegend,

  }) => {

    let col_class = 'col-lg-6 mt-3'
    if (full_width) {
      col_class = 'col-lg-12 mt-3'
    }

    if (typeof titles === 'undefined') {
      var titles = []
      dataset.forEach((value, index) => {
      titles[index] = `Title ${index + 1}`;
      });
    }

    let objectArray = Array.from(Array(dataset.length).keys())

    return (
      <>
      {dataset &&
        objectArray.map((e, i) => (
          <div key={'object_' + i} className={col_class}>
          <div className="box">
            <div className="box-title">{titles[i]}</div>
              <ScatterPlot
                  dataset = {[dataset[i]]}
                  dashLines = {dashLines}
                  range   = {range}
                  xtitle  = {xtitle}
                  ytitle  = {ytitle}
                  marker  = {marker}
                  margins = {margins}
                  legenda = {titles[i]}
                  labeled = {labeled}
                  showlegend = {showlegend}
              />
            </div>
          </div>
        ))
      }
      </>
)}

export default ScatterMultiLayout
