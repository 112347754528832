import { apiSlice } from "../../api/apiSlice"

export const inclusionApiSlice = apiSlice.injectEndpoints({
    endpoints: builder => ({
        getInclusion: builder.query({
            query: (id) => {
                if (id) return `report/WI/inclusion/${id}`;
                throw new Error("ID is undefined");
            },
            keepUnusedDataFor: 600,
        })
    })
})

export const {
    useGetInclusionQuery
} = inclusionApiSlice 