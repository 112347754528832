import {apiSlice} from "../../api/apiSlice"

export const accountSlice = apiSlice.injectEndpoints({
  endpoints: builder => ({
    getAccount: builder.query({
      query: () => '/account/',
      keepUnusedDataFor: 30,
      providesTags: ['Account'],
    }),

    updateAccount: builder.mutation({
      query: (body) => ({
        url: '/account/',
        method: 'PUT',
        body,
      }),
      invalidatesTags: ['Account'],
    }),
  })
})

export const {
  useGetAccountQuery,
  useUpdateAccountMutation,
} = accountSlice

