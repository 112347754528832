import * as XLSX from "xlsx";

export const readXslx = (files, callback) => {
    const reader = new FileReader();
    reader.onload = (evt) => { // evt = on_file_select event
        /* Parse data */
        const bstr = evt.target.result;
        const wb = XLSX.read(bstr, {type: 'binary'});
        /* Get first worksheet */
        const wsname = wb.SheetNames[0];
        const ws = wb.Sheets[wsname];
        /* Convert array of arrays */
        const data = XLSX.utils.sheet_to_json(ws, {header: 1, raw: false});
        /* Update state */
        // if there is an error you have only one line with the error description (or it seems)
        // i produced the error by uploading an xlsx file exported from Numbers (excel for Mac)
        // TODO: maybe find a better error catching?
        if (data[0].length > 1) {
            callback(data, null)
        } else {
            callback(null, "Invalid excel");
        }
    };
    // get last file so is always the current one
    reader.readAsBinaryString(files[files.length - 1]);
}

export const excelToPartecipanti = (data) => {
    const head = data[0]
    data.splice(0, 1)

    const res = []
    data.forEach((e, i) => {
        let tempRow = {}
        head.forEach((f, j) => {
            tempRow[f] = e[j]
        })
        res.push(tempRow)
    })

    return res
}
