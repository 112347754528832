import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import {
  selectPresentation,
  togglePresentation,
  selectNumberParticipants,
} from "../../features/wizard/wizardSlice";
import { PrevNext } from "./components/PrevNext";
import { wizard } from "../../config/routes";

export const Presentation = () => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const count = useSelector(selectNumberParticipants);
  const presentation = useSelector(selectPresentation);

  console.log(wizard)
  
  useEffect(() => {
    if (count === 0) {
      navigate(wizard[1].path);
    }
    // eslint-disable-next-line
  }, []);

  return (
    <>
      <PrevNext
        prevClicked={() => {
          navigate(wizard[1].path);
        }}
        nextClicked={() => {
          navigate(wizard[3].path);
        }}
        step={"3 of 4"}
      />
      <div
        className="container-fluid"
        style={{ paddingTop: 120, paddingBottom: 50 }}
      >
        <div className="row align-items-center">
          <div className="col-lg-6 text-center">
            <div className="m-auto" style={{ maxWidth: 500 }}>
              <div className="number">{count}</div>
              <div
                className="color-blue-dark"
                style={{
                  fontSize: "2.5rem",
                  fontWeight: "bold",
                  lineHeight: "1.2",
                }}
              >
                Participants to be invited
              </div>
            </div>
          </div>
          <div className="col-lg-6 text-center">
            <div
              className="color-blue-dark m-auto mt-5 mt-lg-0"
              style={{ maxWidth: 500 }}
            >
              <div className="">
                <b>
                  Do you want to organize an online presentation via Zoom of the
                  project by one of our managers in collaboration with the
                  Management to increase employee engagement?
                </b>
              </div>
              <div className="mt-4">
                A 1-hour alignment meeting will be organized and a presentation
                to staff (with no limit on the number of participants) which can
                be recorded for distribution off-line
              </div>
            </div>

            <div className="row justify-content-center">
              <div className="col-lg-1 pt-4 ">
                <div className="form-check form-switch">
                  <input
                    className="form-check-input"
                    type="checkbox"
                    id="flexSwitchCheckDefault"
                    checked={presentation}
                    onChange={() => {
                      dispatch(togglePresentation());
                    }}
                    style={{ transform: "scale(2.0)" }}
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};
