export class PieColors {
    static defaultBlue = {name: 'light-blue', light_code: '#009DDD', dark_code: '#009DDD'}
    static red = {name: 'red', light_code: '#FAAA8D', dark_code: '#CC3363'}
    static lightBlue = {name: 'light-blue', light_code: '#009DDD', dark_code: '#05438A'}
    static green = {name: 'green', light_code: '#74E2CF', dark_code: '#17B890'}
    static orange = {name: 'orange', light_code: '#FED766', dark_code: '#EE7B30'}
    static blue = {name: 'blue', light_code: '#CDF156', dark_code: '#0B3954'}
    static magenta = {name: 'magenta', light_code: '#ff00ff', dark_code: '#8b008b'}
    static copper = {name: 'copper', light_code: '#D59D69', dark_code: '#B87333'}
    static goldgreen = {name: 'goldgreen', light_code: '#E5FF8E', dark_code: '#AADD00'}
    static coral = {name: 'coral', light_code: '#FFA382', dark_code: '#FF7F50'}
    static kelly = {name: 'kelly', light_code: '#7AE845', dark_code: '#4CBB17'}
    static chocolate = {name: 'chocolate', light_code: '#FFA477', dark_code: '#993300'}
    static scarlet = {name: 'scarlet', light_code: '#DD3131', dark_code: '#8C1717'}
    static mustard = {name: 'mustard', light_code: '#FFEA9F', dark_code: '#FFCC11'}
    static olive = {name: 'olive', light_code: '#C2C264', dark_code: '#808000'}

}
