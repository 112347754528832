import { useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useForgotMutation } from "../../features/auth/authApiSlice";

function ForgotPassword() {
  const [email, setEmail] = useState(null);
  const [title, setTitle] = useState("Forgot Password");
  const [body, setBody] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitButton, setSubmitButton] = useState("Submit");
  const [errMsg, setErrMsg] = useState("");
  const navigate = useNavigate();

  const [forgot] = useForgotMutation();
  
  useEffect(() => {
    setErrMsg("");
  }, [email]);

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (success) {
      navigate("/login");
    } else {
      try {
        const resp = await forgot(email).unwrap();
        setTitle("We Received Your Request");
        setBody(resp.message);
        setSuccess(true);
        setSubmitButton("Home");
        setEmail("");
      } catch (err) {
        if (!err?.status) {
          setErrMsg("No Server Response");
        } else if (err.status === 400) {
          setErrMsg("Missing email address");
        } else if (err.status === 401) {
          setErrMsg("Request Failed");
        } else {
          setErrMsg("Request Failed");
        }
      }
    }
  };

  return (
    <div className="container vh-100">
      <div className="d-flex justify-content-center h-100 align-items-center">
        <div className="login-content style-2 ">
          <div className="row no-gutters">
            <div className="col-xl-12 bg-white ">
              <div id="sign-in" className="auth-form form-validation">
                {errMsg && (
                  <div className="login-error border p-1 my-2">{errMsg}</div>
                )}
                <form onSubmit={handleSubmit} className="form-validate">
                  <h3 className="text-center mb-4 text-black">{title}</h3>
                  {body && (
                    <p className="text-center mb-4 text-black">{body}</p>
                  )}
                  {!success && (
                    <div className="form-group mb-3">
                      <label className="mb-1" htmlFor="val-email">
                        <strong>Email</strong>
                      </label>
                      <div>
                        <input
                          type="email"
                          className="form-control"
                          value={email}
                          onChange={(e) => setEmail(e.target.value)}
                          placeholder="Type Your Email Address"
                          required
                        />
                      </div>
                    </div>
                  )}

                  <div className="text-center form-group mb-3">
                    <button type="submit" className="btn btn-primary btn-block">
                      {submitButton}
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ForgotPassword;
