import React, { useState } from "react";
import { X } from "react-bootstrap-icons";
import { useNavigate } from "react-router-dom";
import { useChangeEmailMutation } from "../../../features/auth/authApiSlice";
import { PasswordInput } from "../../../components/PasswordInput";
import { EmailInput } from "../../../components/EmailInput";

function ChangeEmailForm({ currentEmail }) {
  const [title, setTitle] = useState(null);
  const [body, setBody] = useState(null);
  const [password, setPassword] = useState("");
  const [newEmail, setNewEmail] = useState("");
  const [errMsg, setErrMsg] = useState("");
  const [success, setSuccess] = useState(false);
  const [submitButtonText, setSubmitButtonText] = useState(
    "Request email change"
  );

  const navigate = useNavigate();
  const [changeEmail] = useChangeEmailMutation();

  const handleChangePasswordInput = (e) => {
    e.preventDefault();
    setPassword(e.target.value);
  };

  const handleChangeEmailInput = (e) => {
    e.preventDefault();
    setNewEmail(e.target.value);
  };

  const handleSubmit = async (e) => {
    e.preventDefault();

    if (success) {
      navigate("/");
    } else {
      try {
        const response = await changeEmail([
          currentEmail,
          newEmail,
          password,
        ]).unwrap();
        setTitle(response.message);
        setBody(response.body);
        setSuccess(true);
        setSubmitButtonText("Go to login...");
        setNewEmail("");
      } catch (err) {
        if (!err?.status) {
          setErrMsg("No Server Response");
        } else if (err.status === 400) {
          setErrMsg(err.data.message);
        } else if (err.status === 401) {
          setErrMsg("Request Failed");
        } else {
          setErrMsg("Request Failed");
        }
      }
    }
  };

  if (success) {
    return (
      <div className="d-flex justify-content-center h-100 align-items-center">
        <div className="row no-gutters">
          <div className="col-xl-12 bg-white">
            <div id="sign-in" className="auth-form form-validation">
              <form onSubmit={handleSubmit} className="form-validate">
                <h3 className="text-center mb-4 text-black">{title}</h3>

                <p className="text-center mb-4 text-grey">{body}</p>
              </form>
            </div>
          </div>
        </div>
      </div>
    );
  }

  return (
    <div className="d-flex justify-content-center h-100 align-items-center">
      <div className="login-content style-2 ">
        <div className="row no-gutters">
          <div className="col-xl-12 bg-white">
            <div id="sign-in" className="auth-form form-validation">
              {errMsg && (
                <div className="login-error border p-1 my-2 error-msg">
                  {errMsg}
                </div>
              )}

              <form onSubmit={handleSubmit} className="form-validate">
                <h3 className="text-center mb-4 text-black">{title}</h3>

                <EmailInput
                  id="currentEmailAddress"
                  label={"Current email address"}
                  value={currentEmail}
                  disabled={true}
                />

                <EmailInput
                  id="newEmailAddress"
                  label={"New email address"}
                  value={newEmail}
                  handleChange={handleChangeEmailInput}
                />

                <PasswordInput
                  id="currentPassword"
                  label={"Current Password"}
                  value={password}
                  handleChange={handleChangePasswordInput}
                  
                />

                {newEmail === currentEmail && (
                  <div className="col-6 pt-3 my-4 mt-xxl-0 w-auto h-auto">
                    <div
                      className={`alert alert-warning px-4 py-3 mb-0`}
                      role="alert"
                    >
                      <ul className="list-unstyled mb-0">
                        <li className="text-nowrap">
                          <X className="text-danger me-2" size={30} />
                          New and current email address must be different.
                        </li>
                      </ul>
                    </div>
                  </div>
                )}

                <div className="text-center form-group mb-3">
                  <button
                    type="submit"
                    className="btn btn-primary btn-block"
                    disabled={
                      !newEmail || !password || newEmail === currentEmail
                    }
                  >
                    {submitButtonText}
                  </button>
                </div>
              </form>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ChangeEmailForm;
