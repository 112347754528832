import ViolinCompare from "./ViolinCompare";

const ViolinCompareLayout = ({
  title = "this Is a title",
  data_before,
  data_after,
  scatters = [0],
}) => {

  let margins = [100, 100, 10, 100];

  return (
    <>
      {scatters.map((e, i) => (
        <div key={"scatter_" + i} className="col-lg-6  mt-3">
          <div className="box">
            <div className="d-flex box-title justify-content-between">
              <div className="box-title">{title}</div>
            </div>

            <ViolinCompare 
            data_before={data_before}
            data_after={data_after}
            margins = {margins}
             />
          </div>
        </div>
      ))}
    </>
  );
};

export default ViolinCompareLayout;
