import { useSelector } from "react-redux";
import {
  SelectCurrentFilters,
  SelectAllFilters,
  SelectCurrentProject,
} from "../../features/projects/projectSlice";
import { selectActiveRole } from "../../features/auth/authSlice";
import { useGetIdentificationQuery } from "../../features/report/identificationApiSlice";
import { filterData, reformatScatterData } from "../functions/functions";
import ViolinLayout from "../graph/ViolinLayout";
import ScatterStackedLayout from "../graph/ScatterStackedLayout";
import Loader from "../Loader";
import FilterBar from "../../layout/FilterBar";

const Identification = () => {
  const selectedProject = useSelector(SelectCurrentProject);
  const selectedFilters = useSelector(SelectCurrentFilters);
  const filterArray = useSelector(SelectAllFilters);
  const activeRole = useSelector(selectActiveRole);

  let showDataPoints = false;
  activeRole === "user_admin"
    ? (showDataPoints = true)
    : (showDataPoints = false);

  const { data, isLoading, isSuccess, isError, error } =
    useGetIdentificationQuery(selectedProject);

  const handleFilterCheck = () => {
    return (
      activeRole === "user_admin" &&
      selectedFilters.length > 0 &&
      selectedFilters[0] !== "Overall"
    );
  };

  let scatterData = null;
  let scaleData = null;
  let filter = filterArray[selectedFilters[0]];
  if (isLoading) {
    return <Loader />;
  } else if (isSuccess) {
    scaleData = filterData(data, filter);
    scatterData = reformatScatterData(
      data.scatter_data,
      filter,
      data.scatter_scale_codes
    );
  } else if (isError) {
    return <p>{JSON.stringify(error)}</p>;
  }

  return (
    <>
      <FilterBar selectedFilters={selectedFilters} />
      <div className="container">
        <div className="row">
          {scaleData &&
            scaleData.map((item, i) => {
              return (
                <ViolinLayout
                  multiple={handleFilterCheck()}
                  markerData={data.scale_user_values[i]}
                  marker={!showDataPoints && !handleFilterCheck()}
                  showDataPoints={showDataPoints && !handleFilterCheck()}
                  title={data.scale_titles[i]}
                  info_button = {true}
                  scale_info= {data.scale_descriptions[i]}
                  dataset={item}
                />
              );
            })}

          {scatterData && activeRole === "user_admin" && (
            <ScatterStackedLayout
              dataset={scatterData[0]}
              legenda={scatterData[1]}
              showlegend={scatterData[1].length !== 0}
              dashLines = {true}
              labeled={true}
              full_width={false}
              range={[
                [0, 6.1],
                [0, 6.1],
              ]}
              zeroline={false}
              titles={data.scatter_titles[0]}
            />
          )}
        </div>
      </div>
    </>
  );
};

export default Identification;
