export function splitArray(data, basetitle) {
  // create list of labels
  if (!data) return [[], []];

  let dataArrays = [];
  let labels = data
    .map((value) => value.target)
    .filter((value, index, _arr) => _arr.indexOf(value) === index);

  labels.forEach((label) => {
    dataArrays.push(data.filter((e) => e.target === label));
  });

  labels[0] = labels[0] === "costrutto" ? basetitle : labels[0];

  return [dataArrays, labels];
}

export function splitData(data, basetitle) {
  // create list of labels
  if (!data) return [[], []];
  let dataArrays = [];
  let labels = data
    .map((value) => value.filter_value)
    .filter((value, index, _arr) => _arr.indexOf(value) === index);

  labels.forEach((label) => {
    dataArrays.push(data.filter((e) => e.filter_value === label));
  });

  labels[0] = labels[0] === "costrutto" ? basetitle : labels[0];

  return [dataArrays, labels];
}

export function reformatData(data) {
  const categories = [...new Set(data.map((value) => value.target))];
  const avg = categories.map((label) =>
    unpack_avg(
      data.filter((e) => e.target === label),
      label
    )
  );

  return [avg, categories];
}

// takes an array and a key and returns all the keyvalues as an array
export function unpack(rows, key) {
  return rows.map((row) => row[key]);
}

export function unpack_avg(rows, label) {
  const filteredRows = rows.filter(
    (row) => row.hasOwnProperty("x") || row.hasOwnProperty("y")
  );
  const sum = { x: 0, y: 0 };
  const count = { x: 0, y: 0 };

  filteredRows.forEach((row) => {
    if (row.hasOwnProperty("x")) {
      sum.x += row.x;
      count.x++;
    }
    if (row.hasOwnProperty("y")) {
      sum.y += row.y;
      count.y++;
    }
  });

  const avg = {
    x: count.x > 0 ? (sum.x / count.x).toFixed(2) : 0,
    y: count.y > 0 ? (sum.y / count.y).toFixed(2) : 0,
  };

  return [{ target: label, ...avg, label }];
}

export const exportData = (data) => {
  const jsonString = `data:text/json;chatset=utf-8,${encodeURIComponent(
    JSON.stringify(data)
  )}`;
  const link = document.createElement("a");
  link.href = jsonString;
  link.download = "data.json";

  link.click();
};

export function filterData(data, filter) {
  return data.scale_data.map((item) =>
    item
      .filter((row) => row["value"] !== null && row[filter] !== null)
      .map((row) => ({ target: row[filter], score: row["value"] }))
  );
}

export function reformatScatterData(data, filter, scatterCodes) {
  
  if (!data || data.length === 0) return null;
  let input = [];
  
  let keyX = scatterCodes[0]
  let keyY = scatterCodes[1]
  data.forEach((row) => {
    if (row[keyX] && row[keyY]) {
      input.push({
        label: row[filter],
        target: row[filter],
        x: row[keyX],
        y: row[keyY],
      });
    }
  });

  if (filter === "costrutto") {
    return [[input], []];
  } else {
    return reformatData(input, filter);
  }
}
